/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import "assets/scss/page/_main.scss";
import { useListCategory } from "hooks";
import { useSearch } from "context/SearchContext";
import Masonry from "react-masonry-css";
import { useNavigate } from "react-router-dom";

const Main = ({ getSettingData, t }) => {
  const { searchTerm } = useSearch();
  const navigate = useNavigate();

  const searchFilter = searchTerm.length >= 3 ? searchTerm : null;

  const {
    data,
    isLoading,
    error,
    refetch: doGetcategories,
  } = useListCategory({
    search_text_filter: "",
  });

  const [categoriesData, setCategoriesData] = useState({});

  useEffect(() => {
    if (data) {
      const organizedCategories = {};
      data.data.category_list.forEach((category) => {
        organizedCategories[category.parent_category_name] =
          category.sub_categories.sort(
            (a, b) => a.sequence - b.sequence // Sort subcategories by sequence
          );
      });
      setCategoriesData(organizedCategories);
    }
  }, [data]);

  const handleClickRedirectPostListing = (e, item) => {
    e.preventDefault();
    const category = {
      category_id: item.category_id,
      category_name: item.category_name,
      parent_id: item.parent_id ? item.parent_id : item.category_id,
      parentOrChild: "",
    };
    localStorage.setItem("category", JSON.stringify(category));
    navigate(`/post-advertisement/post-list`, {
      state: { category },
    });
  };

  const renderCategoryList = (items, category) => {
    if (category == "Services" || items.length <= 22) {
      return (
        <ul className="list-unstyled category-list">
          {items.map((item, index) => (
            <li
              key={index}
              onClick={(e) => handleClickRedirectPostListing(e, item)}
            >
              <a
                href="#"
                onClick={(e) => handleClickRedirectPostListing(e, item)}
                className="hover-underline"
              >
                {item.category_name}
              </a>
            </li>
          ))}
        </ul>
      );
    }

    // If more than 2 items, divide into two columns
    const midIndex = Math.ceil(items.length / 2);
    const firstHalf = items.slice(0, midIndex);
    const secondHalf = items.slice(midIndex);

    return (
      <Row>
        <Col xs={12} md={6} className="pe-md-0">
          <ul className="list-unstyled category-list">
            {firstHalf.map((item, index) => (
              <li
                key={index}
                onClick={(e) => handleClickRedirectPostListing(e, item)}
              >
                <a
                  href="#"
                  onClick={(e) => handleClickRedirectPostListing(e, item)}
                  className="hover-underline"
                >
                  {item.category_name}
                </a>
              </li>
            ))}
          </ul>
        </Col>
        <Col xs={12} md={6} className="ps-md-1">
          <ul className="list-unstyled category-list">
            {secondHalf.map((item, index) => (
              <li
                key={index}
                onClick={(e) => handleClickRedirectPostListing(e, item)}
              >
                <a
                  href="#"
                  onClick={(e) => handleClickRedirectPostListing(e, item)}
                  className="hover-underline"
                >
                  {item.category_name}
                </a>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    );
  };

  // Define the breakpoint columns for masonry
  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  return (
    <Container fluid className="content-area home-container">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {isLoading && <div>Loading...</div>}
        {error && <div>Error: {error.message}</div>}

        {Object.keys(categoriesData).map((categoryName) => {
          const categoryList = categoriesData[categoryName];
          return (
            <div key={categoryName}>
              <Card className="mb-4 bg-transparent  border-0 text-white p-0">
                <div
                  className="custom-heading"
                  onClick={(e) => {
                    e.preventDefault();
                    const category = {
                      category_id: "",
                      category_name: categoryName,
                      parent_id: categoryList[0].parent_id,
                      parentOrChild: "parent",
                    };
                    localStorage.setItem("category", JSON.stringify(category));
                    navigate(`/post-advertisement/post-list`, {
                      state: { category },
                    });
                  }}
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      const category = {
                        category_id: "",
                        category_name: categoryName,
                        parent_id: categoryList[0].parent_id,
                        parentOrChild: "parent",
                      };
                      localStorage.setItem(
                        "category",
                        JSON.stringify(category)
                      );
                      navigate(`/post-advertisement/post-list`, {
                        state: { category },
                      });
                    }}
                    className="hover-underline"
                  >
                    {categoryName}
                  </a>
                </div>
                <Card.Body>
                  {renderCategoryList(
                    categoriesData[categoryName],
                    categoryName
                  )}
                </Card.Body>
              </Card>
            </div>
          );
        })}
      </Masonry>
    </Container>
  );
};

export default Main;
