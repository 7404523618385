import React, { useEffect, useState } from "react";
import { Accordion, Nav } from "react-bootstrap";
import PropTypes, { object } from "prop-types";
import "assets/scss/section/_private_sidebar.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutSuccess } from "store/features/authSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import user_icon from "assets/images/account_circle.svg";
import path from "assets/images/Path.svg";
import add_card from "assets/images/add_card.svg";
import business_user from "assets/images/business_user.svg";
import description from "assets/images/description.svg";
import favorite from "assets/images/favorite.svg";
import history from "assets/images/history.svg";
import move_item from "assets/images/move_item.svg";
import receipt_long from "assets/images/receipt_long.svg";
import reviews from "assets/images/reviews.svg";
import { useGetLastAds, useListBusinessUsers } from "hooks";
import { toast } from "react-toastify";

const PrivateSidebar = ({ isOpen, toggleSidebar, getSettingData, t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate("/");
  };
  const [activeKey, setActiveKey] = useState(null);
  const [isBusinessStatusPending, setIsBusinessStatusPending] = useState(null);
  const [previousAds, setPreviousAds] = useState([]);
  const user = useSelector((state) => state?.user);
  const { refetch: doGetList, isLoading: listingData } = useListBusinessUsers(
    (res) => {
      if (res.data) {
        const user = res.data;
        setIsBusinessStatusPending(user.status);
      }
    },
    (error) => {
      toast.error(error.message);
    }
  );
  const { mutate: doGetLastAds, isLoading: gettingAds } = useGetLastAds(
    (res) => {
      if (Object.keys(res?.data).length > 0) {
        setPreviousAds([res?.data]);
      }
    },
    (error) => {
      toast.error(error.message);
    }
  );
  useEffect(() => {
    if (user?.user?.user_id != undefined) {
      doGetLastAds({ user_id: user?.user?.user_id });
    }
  }, []);

  const handleCreatePostBtn = () => {
    if (previousAds.length > 0) {
      navigate("/ads/create-ads", {
        state: {
          subcategoryIds: previousAds[0]?.sub_category_id,
          parentCategory: previousAds[0]?.category_id,
          advertisementId: previousAds[0]?.advertisement_id,
          step: previousAds[0]?.step,
        },
      });
    } else {
      navigate("/ads/select-category");
    }
    toggleSidebar();
  };
  const handleToggle = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };
  const handleCanvasButtonClick = () => {
    // // Get the collection of elements with the class name "offcanvas"
    const elements = document.getElementsByClassName("offcanvas");

    // Check if there are any elements with this class
    if (elements.length > 0) {
      // Access the first element in the collection
      const element = elements[0];

      // Remove the "show" class from the first element
      element.classList.remove("show");
      const toggle = document.getElementsByClassName("left-toggle");
      toggle[0].click();
      // Log the element to the console
    } else {
      console.log("No elements found with the class 'offcanvas'.");
    }
  };

  const step = useSelector((state) => state.advertisement.currentStepperStep);

  return (
    <div
      className={`pvt-sidebar  vh-100 text-white p-3 ${
        isOpen ? "pvt-sidebar-open" : "pvt-sidebar-closed"
      }`}
    >
      <button
        className="pvt-sidebar-close-btn"
        onClick={toggleSidebar}
        aria-label="Close sidebar"
      >
        &times;
      </button>

      <Nav className="flex-column mt-4 sidebar-content">
        <Link
          to={"/edit-profile"}
          className="text-white text-decoration-none ms-md-3 my-2"
          onClick={toggleSidebar}
        >
          <img src={user_icon} alt="" /> {t("front_page.sidebar_my_profile")}
        </Link>
        {isBusinessStatusPending != 1 ? (
          <>
            <Link
              to={"/become-business-user"}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <div className="d-flex">
                <img src={business_user} alt="" />
                <div>{t("front_page.sidebar_become_business_user")}</div>
              </div>
            </Link>
            <Accordion activeKey={activeKey} onSelect={handleToggle}>
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  className={activeKey === "0" ? "custom-acc-header " : "my-2"}
                >
                  {/* Arrow indicator */}
                  {activeKey === "0" ? <FaChevronUp /> : <FaChevronDown />}
                  <img src={path} alt="" className="me-3 ms-1" />
                  {t("front_page.sidebar_ads_managements")}
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column">
                  <span
                    className="text-white my-2 text-decoration-none ms-md-3"
                    onClick={handleCreatePostBtn}
                    style={{ cursor: "pointer" }}
                  >
                    {t("front_page.sidebar_create_ads")}
                  </span>

                  <Link
                    to={"/ads/list-ads"}
                    className="text-white my-2 text-decoration-none ms-md-3"
                    onClick={toggleSidebar}
                  >
                    {t("front_page.sidebar_ads_list")}
                  </Link>

                  <Nav.Link
                    href="#completed-ads"
                    onClick={toggleSidebar}
                    className="text-white"
                  >
                    {t("front_page.sidebar_completed_ads")}
                  </Nav.Link>
                  <Nav.Link
                    href="#cancelled-ads"
                    onClick={toggleSidebar}
                    className="text-white"
                  >
                    {t("front_page.sidebar_cancelled_ads")}
                  </Nav.Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Nav.Link
              href="#favourite"
              onClick={toggleSidebar}
              className="text-white my-2"
            >
              <img src={favorite} alt="" /> {t("front_page.sidebar_favorite")}
            </Nav.Link>
            <Link
              to={"/manage-card"}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <img src={add_card} alt="" />{" "}
              {t("front_page.sidebar_manage_cards")}
            </Link>
            <Link
              to={"/payment-history"}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <img src={receipt_long} alt="" />{" "}
              {t("front_page.sidebar_payment_history")}
            </Link>

            <Nav.Link
              href="#reviews"
              onClick={toggleSidebar}
              className="text-white my-2"
            >
              <img src={reviews} alt="" /> {t("front_page.sidebar_review")}
            </Nav.Link>
          </>
        ) : (
          <>
            <Link
              to={"/edit-become-business-user"}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <img src={business_user} alt="" />{" "}
              {t("front_page.sidebar_business_profile")}
            </Link>
            <Accordion activeKey={activeKey} onSelect={handleToggle}>
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  className={activeKey === "0" ? "custom-acc-header " : "my-2"}
                >
                  {/* Arrow indicator */}
                  {activeKey == "0" ? <FaChevronUp /> : <FaChevronDown />}
                  <img src={path} alt="" className="me-3 ms-1" />
                  {t("front_page.sidebar_ads_managements")}
                </Accordion.Header>
                <Accordion.Body className="d-flex flex-column">
                  <span
                    className="text-white my-2 text-decoration-none ms-md-3"
                    onClick={handleCreatePostBtn}
                    style={{ cursor: "pointer" }}
                  >
                    {t("front_page.sidebar_create_ads")}
                  </span>
                  {/* <Link
                    to={
                      previousAds.length > 0
                        ? {
                            pathname: "/ads/create-ads",
                            state: {
                              subcategoryIds: previousAds[0]?.sub_category_id,
                              parentCategory: previousAds[0]?.category_id,
                              advertisementId: previousAds[0]?.advertisement_id,
                            },
                          }
                        : "/ads/select-category"
                    }
                    className="text-white my-2 text-decoration-none ms-md-3"
                    onClick={toggleSidebar}
                  >
                    {t("front_page.sidebar_create_ads")}
                  </Link> */}
                  <Link
                    to={"/ads/list-ads"}
                    className="text-white my-2 text-decoration-none ms-md-3"
                    onClick={toggleSidebar}
                  >
                    {t("front_page.sidebar_ads_list")}
                  </Link>

                  <Nav.Link
                    href="#completed-ads"
                    onClick={toggleSidebar}
                    className="text-white"
                  >
                    {t("front_page.sidebar_completed_ads")}
                  </Nav.Link>
                  <Nav.Link
                    href="#cancelled-ads"
                    onClick={toggleSidebar}
                    className="text-white"
                  >
                    {t("front_page.sidebar_cancelled_ads")}
                  </Nav.Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Nav.Link
              href="#favourite"
              onClick={toggleSidebar}
              className="text-white my-2"
            >
              <img src={favorite} alt="" /> {t("front_page.sidebar_favorite")}
            </Nav.Link>
            <Link
              to={"/manage-card"}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <img src={add_card} alt="" />{" "}
              {t("front_page.sidebar_manage_cards")}
            </Link>

            <Link
              to={"/payment-history"}
              className="text-white my-2 text-decoration-none ms-md-3"
              onClick={toggleSidebar}
            >
              <img src={receipt_long} alt="" />{" "}
              {t("front_page.sidebar_payment_history")}
            </Link>

            <Nav.Link
              href="#reviews"
              onClick={toggleSidebar}
              className="text-white my-2"
            >
              <img src={reviews} alt="" /> {t("front_page.sidebar_review")}
            </Nav.Link>
          </>
        )}

        <Nav.Link
          onClick={() => {
            handleLogout();
            toggleSidebar();
          }}
          className="text-white my-2"
        >
          <img src={move_item} alt="" /> {t("front_page.sidebar_logout")}
        </Nav.Link>
      </Nav>
    </div>
  );
};

PrivateSidebar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired, // Add toggleSidebar function prop
};

export default PrivateSidebar;
