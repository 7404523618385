import React from 'react'

function UserProfileJobIcon() {
    return (
        <>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.66665 29.7497C4.88748 29.7497 4.22047 29.4722 3.6656 28.9174C3.11074 28.3625 2.83331 27.6955 2.83331 26.9163V11.333C2.83331 10.5538 3.11074 9.88683 3.6656 9.33197C4.22047 8.7771 4.88748 8.49967 5.66665 8.49967H11.3333V5.66634C11.3333 4.88717 11.6107 4.22016 12.1656 3.6653C12.7205 3.11044 13.3875 2.83301 14.1666 2.83301H19.8333C20.6125 2.83301 21.2795 3.11044 21.8344 3.6653C22.3892 4.22016 22.6666 4.88717 22.6666 5.66634V8.49967H28.3333C29.1125 8.49967 29.7795 8.7771 30.3344 9.33197C30.8892 9.88683 31.1666 10.5538 31.1666 11.333V26.9163C31.1666 27.6955 30.8892 28.3625 30.3344 28.9174C29.7795 29.4722 29.1125 29.7497 28.3333 29.7497H5.66665ZM14.1666 8.49967H19.8333V5.66634H14.1666V8.49967ZM28.3333 21.2497H21.25V24.083H12.75V21.2497H5.66665V26.9163H28.3333V21.2497ZM15.5833 21.2497H18.4166V18.4163H15.5833V21.2497ZM5.66665 18.4163H12.75V15.583H21.25V18.4163H28.3333V11.333H5.66665V18.4163Z" fill="white" />
            </svg>
        </>
    )
}

export default UserProfileJobIcon