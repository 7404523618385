import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import PrivateHeader from "../Header/PrivateHeader";
import PrivateSidebar from "../Sidebar/PrivateSidebar";

const PrivateLayout = ({ getSettingData, children, t }) => {
  // const { t } = useTranslation();
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [currentView, setCurrentView] = useState("desktop");
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebarVisible(false);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 992px)");
    const handleMediaChange = (e) => {
      setCurrentView(e.matches ? "phone" : "desktop");
    };

    handleMediaChange(mediaQuery);
    mediaQuery.addListener(handleMediaChange);

    // Event listener for clicks outside the sidebar
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      mediaQuery.removeListener(handleMediaChange);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={`${currentView === "desktop" ? "super-container" : ""}`}>
        <div className="main-content-private">
          <div
            className={`d-flex flex-column flex-grow-1 bg-theme ${
              currentView === "desktop"
                ? "private-container"
                : "main-content-private-custom"
            }`}
          >
            <PrivateHeader
              toggleSidebar={toggleSidebar}
              getSettingData={getSettingData}
              t={t}
            />
            <div
              className={`flex-grow-1 text-light ${
                currentView === "desktop"
                  ? "centered-content mt-3"
                  : "custom-centered-content mt-3"
              }`}
            >
              {children}
              <div ref={sidebarRef}>
                <PrivateSidebar
                  isOpen={isSidebarVisible}
                  toggleSidebar={toggleSidebar}
                  getSettingData={getSettingData}
                  t={t}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.any.isRequired,
  active: PropTypes.string,
};

export { PrivateLayout };
