import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClientProvider, QueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import PagesRoutes from "routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "assets/scss/style.scss";
import { persistor, store } from "store";
import { setAuthToken, setAuthType } from "libs";
import { loadStripe } from "@stripe/stripe-js"; // Import Stripe
import { Elements } from "@stripe/react-stripe-js"; // Import Elements
import Notification from "../firebase/Notification";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

// Create a Stripe instance
const stripePromise = loadStripe(
  "pk_test_51MocHNEWz4dHQzMH7owiyuF2n2XBeHpKwYsW8KXCBFuSddmFD5cXaF7OcQvGUWmHXxo123MKSPiKoVahek4eTFXV00uqQKqkQr"
);

function App() {
  const { t } = useTranslation();

  window.ononline = (event) => {
    window.location.reload(true);
  };

  const handleOnBeforeLift = () => {
    if (
      store.getState().user.accessToken !== undefined &&
      store.getState().user.accessToken !== null
    ) {
      setAuthToken(store.getState().user.accessToken);
    }
    setAuthType("front");
  };
  useEffect(() => {
    // Register Service Worker for Firebase Messaging
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope: ",
            registration.scope
          );
        })
        .catch((err) => {
          console.error("Service Worker registration failed: ", err);
        });
    }
  }, []); // Empty dependency array ensures this runs once when the app loads
  return (
    <Elements stripe={stripePromise}>
      <Suspense>
        <Provider store={store}>
          <PersistGate
            loading={null}
            persistor={persistor}
            onBeforeLift={handleOnBeforeLift}
          >
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <PagesRoutes t={t} />
                <Notification />
                <ToastContainer />
              </BrowserRouter>
            </QueryClientProvider>
          </PersistGate>
        </Provider>
      </Suspense>
    </Elements>
  );
}

export default App;
