import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
  useAddStripeCard,
  useDeleteStripeCards,
  useGetStripeCards,
} from "hooks";
import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Form,
  ListGroup,
  Row,
  Col,
  Spinner,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Formik } from "formik";
import validationSchema from "./ManageCardValidation";

const ManageCard = () => {
  const [showModal, setShowModal] = useState(false);
  const [cards, setCards] = useState([]); // State to store added cards
  const [deletingCardId, setDeletingCardId] = useState(null); // Track which card is being deleted
  const stripe = useStripe();
  const elements = useElements();
  const [selectedCardId, setSelectedCardId] = useState(null);

  const handleCardSelect = (cardId) => {
    setSelectedCardId(cardId);
  };

  const { refetch: doGetcards, isLoading: gettingCards } = useGetStripeCards(
    ({ data: cards }) => {
      setCards(cards.cards);
      console.log("cards: ", cards);
    }
  );

  const { mutate: doAddCard, isLoading: addingCard } = useAddStripeCard(
    (res) => {
      toast.success(res.message);
      console.log("res: ", res);
      doGetcards();
    }
  );

  const { mutate: doDelete } = useDeleteStripeCards((res) => {
    toast.success(res.message);
    console.log("res: ", res);
    setDeletingCardId(null); // Reset deleting state
    doGetcards();
  });

  const handleAddCard = async (values) => {
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: values.cardOwner,
      },
    });

    if (error) {
      console.error("Error creating payment method:", error);
    } else {
      console.log("Payment Method Created:", paymentMethod);
      doAddCard({
        payment_method_id: paymentMethod.id,
        card_owner: values.cardOwner,
      });
      setShowModal(false); // Close the modal
    }
  };

  useEffect(() => {
    if (cards.length > 0) {
      setSelectedCardId(cards[0].id);
    }
  }, [cards]);

  const handleDeleteCard = (id) => {
    setDeletingCardId(id); // Set the card being deleted
    doDelete({ card_id: id });
  };

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="payment-add-card-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100">Add New Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ cardOwner: "" }}
            validationSchema={validationSchema}
            onSubmit={handleAddCard}
          >
            {({ handleSubmit, handleChange, values, errors, touched }) => (
              <Form onSubmit={handleSubmit} noValidate>
                <Form.Group controlId="cardOwner" className="mt-3">
                  <Form.Label>Card Owner</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter the name on the card"
                    value={values.cardOwner}
                    onChange={handleChange}
                    isInvalid={touched.cardOwner && !!errors.cardOwner}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cardOwner}
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="mt-4">
                  <CardElement
                    className="border p-2"
                    options={{ hidePostalCode: true }}
                  />
                </div>

                <Button type="submit" className="mt-5 w-100" disabled={!stripe}>
                  {addingCard ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>

      {/* List of Added Cards */}
      <h3 className="mt-3">Manage Card</h3>
      <Row className="d-flex justify-content-center payment-add-card">
        <Col md={5}>
          <ListGroup className="mt-5">
            <div className="d-flex">
              <h5>Primary card</h5>
            </div>
            {cards.length === 0 ? (
              <div className="text-center mt-3">
                <p>No cards added.</p>
              </div>
            ) : (
              cards.map((card) => (
                <div className="my-2" key={card.id}>
                  <div className="radio-btn-payment">
                    <div className="d-flex align-items-center position-relative">
                      {/* Custom radio button */}
                      <span className="custom-radio-position">
                        <input
                          type="radio"
                          id={`card-${card.id}`}
                          name="cardSelection"
                          checked={selectedCardId === card.id}
                          onChange={() => handleCardSelect(card.id)}
                          className="custom-radio me-2"
                        />
                        <label
                          htmlFor={`card-${card.id}`}
                          className="custom-radio-label"
                        ></label>
                      </span>
                      <ListGroup.Item
                        className="d-flex justify-content-between align-items-center border w-100 rounded bg-theme text-light"
                        onClick={() => handleCardSelect(card.id)} // Clickable area for card
                        style={{ cursor: "pointer" }} // Change cursor to pointer
                      >
                        <div className="d-flex align-items-center">
                          <div>
                            <strong>{card.card.brand}</strong>
                            {"**********" + card.card.last4}
                          </div>
                        </div>
                        <Button
                          variant="danger"
                          className="remove-card"
                          onClick={() => handleDeleteCard(card.id)}
                        >
                          {deletingCardId === card.id ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            <FontAwesomeIcon icon={faTrash} />
                          )}
                        </Button>
                      </ListGroup.Item>
                    </div>
                  </div>
                </div>
              ))
            )}
            <div className="d-flex justify-content-center mt-3">
              <Button className="w-100" onClick={() => setShowModal(true)}>
                Add Card
              </Button>
            </div>
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
};

export default ManageCard;
