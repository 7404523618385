import React from 'react'

function DollarIcon({className }) {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}  style={{minWidth:"24px"}}>
                <path d="M9.18746 17.5V15.7083C8.45135 15.5417 7.81593 15.2222 7.28121 14.75C6.74649 14.2778 6.35413 13.6111 6.10413 12.75L7.64579 12.125C7.85413 12.7917 8.16315 13.2986 8.57288 13.6458C8.9826 13.9931 9.52079 14.1667 10.1875 14.1667C10.7569 14.1667 11.2395 14.0382 11.6354 13.7812C12.0312 13.5243 12.2291 13.125 12.2291 12.5833C12.2291 12.0972 12.0763 11.7118 11.7708 11.4271C11.4652 11.1424 10.7569 10.8194 9.64579 10.4583C8.45135 10.0833 7.6319 9.63542 7.18746 9.11458C6.74301 8.59375 6.52079 7.95833 6.52079 7.20833C6.52079 6.30556 6.81246 5.60417 7.39579 5.10417C7.97913 4.60417 8.57635 4.31944 9.18746 4.25V2.5H10.8541V4.25C11.5486 4.36111 12.1215 4.61458 12.5729 5.01042C13.0243 5.40625 13.3541 5.88889 13.5625 6.45833L12.0208 7.125C11.8541 6.68056 11.618 6.34722 11.3125 6.125C11.0069 5.90278 10.5902 5.79167 10.0625 5.79167C9.45135 5.79167 8.98607 5.92708 8.66663 6.19792C8.34718 6.46875 8.18746 6.80556 8.18746 7.20833C8.18746 7.66667 8.39579 8.02778 8.81246 8.29167C9.22913 8.55556 9.95135 8.83333 10.9791 9.125C11.9375 9.40278 12.6632 9.84375 13.1562 10.4479C13.6493 11.0521 13.8958 11.75 13.8958 12.5417C13.8958 13.5278 13.6041 14.2778 13.0208 14.7917C12.4375 15.3056 11.7152 15.625 10.8541 15.75V17.5H9.18746Z" fill="white" />
            </svg>
        </>
    )
}

export default DollarIcon