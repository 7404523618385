import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";
import Checkout from "pages/Checkout/Checkout";

const CreateAdsPage = React.lazy(() => import("./CreateAdsPage"));
const SelectCategory = React.lazy(() => import("./SelectCategoryPage"));
const SelectSubCategory = React.lazy(() => import("./SelectSubCategoryPage"));
const AdsPreview = React.lazy(() => import("./AdsPreview"));
const ListAds = React.lazy(() => import("./ListAdsPage"));
const EditAds = React.lazy(() => import("./EditAdsPage"));
const ViewAds = React.lazy(() => import("./ViewAdsPage"));
const AdsRoutes = ({ getSettingData, t }) => {
  return (
    <Routes>
      <Route
        exact
        path={`/create-ads`}
        element={<CreateAdsPage getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={`/select-category`}
        element={<SelectCategory getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={`/select-sub-category`}
        element={<SelectSubCategory getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={`/preview`}
        element={<AdsPreview getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={`/list-ads`}
        element={<ListAds getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={`/edit-ads`}
        element={<EditAds getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path={`/view-ads`}
        element={<ViewAds getSettingData={getSettingData} t={t} />}
      />
      <Route
        exact
        path="/ads_checkout"
        element={<Checkout getSettingData={getSettingData} t={t} />}
      />
      <Route exact path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
AdsRoutes.propTypes = {
  t: PropTypes.func,
};
export default AdsRoutes;
