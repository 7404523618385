import React from 'react'

function RightIcon() {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clipRule="evenodd" d="M13.2197 4.96967C13.5126 4.67678 13.9874 4.67678 14.2803 4.96967L20.5303 11.2197C20.8232 11.5126 20.8232 11.9874 20.5303 12.2803L14.2803 18.5303C13.9874 18.8232 13.5126 18.8232 13.2197 18.5303C12.9268 18.2374 12.9268 17.7626 13.2197 17.4697L18.1893 12.5H3.75C3.33579 12.5 3 12.1642 3 11.75C3 11.3358 3.33579 11 3.75 11H18.1893L13.2197 6.03033C12.9268 5.73744 12.9268 5.26256 13.2197 4.96967Z" fill="white" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export default RightIcon