import client from "../libs/HttpClient";
class BusinessUserService {
  static sendForm(request) {
    return client.post("business-user/send-form", request, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  }
  static listData(request) {
    return client.get("business-user/list-form", request);
  }
  static listBusinessType(request) {
    return client.get("business-user/business-type-list", request);
  }
}
export { BusinessUserService };
