import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  new_password: Yup.string()
    .required("front_page.change_pass_new_password_required")
    .min(8, "front_page.change_pass_password_min_length")
    .matches(/[a-zA-Z]/, "front_page.change_pass_password_letters")
    .matches(/[0-9]/, "front_page.change_pass_password_numbers"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("new_password"), null],
      "front_page.change_pass_passwords_must_match"
    )
    .required("front_page.change_pass_confirm_password_required"),
});

export default validationSchema;
