import Widget from "common/components/Widget";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

const Dashboard = () => {
  const fcmToken = useSelector((state) => state.notifications.fcmToken);
  return (
    <div className="dashboard p-3">
      <h1>Dashboard</h1>
    </div>
  );
};

export default Dashboard;
