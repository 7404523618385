export const commonOption = (t) => {
  return [
    { value: "", label: `${t("front_page.select_status")}` },
    { value: 1, label: `${t("front_page.active_status_name")}` },
    { value: 2, label: `${t("front_page.in_active_status_name")}` },
  ];
};
export const paymentStatusOptions = (t) => {
  return [
    { value: "", label: `${t("front_page.select_status")}` },
    { value: 1, label: `${t("front_page.success_status")}` },
    { value: 2, label: `${t("front_page.failed_status")}` },
  ];
};
