import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  businessName: Yup.string()
    .required("Business Name is required")
    .min(2, "Business Name must be at least 2 characters long"),

  // businessType: Yup.number().required("Business Type is required"),

  location: Yup.string()
    .required("Location is required")
    .min(2, "Location must be at least 2 characters long"),

  webUrl: Yup.string()
    .url("Invalid URL format")
    .required("Website URL is required"),
});

export default validationSchema;
