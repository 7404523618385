import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { AdverstisementService } from "api";
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useGetParentCategoryList = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["parent_category_list"],
    () => {
      return AdverstisementService.parentCategoryNameList();
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetChildCategoryList = (
  params,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ["child_category_list", params],
    () => {
      return AdverstisementService.childCategoryNameList({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetAdverstisementList = (
  params,
  onSuccess,
  onError = onDefaultError
) => {
  return useQuery(
    ["advertisement_list", params],
    () => {
      return AdverstisementService.adverstisementList({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useGetCitiesByState = (params,onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["list_cities",params],
    () => {
      return AdverstisementService.getCitiesByState({params});
    },
    {
      onSuccess,
      onError,
    }
  );
};

export { useGetParentCategoryList, useGetChildCategoryList,useGetAdverstisementList,useGetCitiesByState };
