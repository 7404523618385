import FullScreenHeader from "common/layouts/Header/FullScreenHeader";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import "../../assets/scss/page/_user_profile.scss";
import ShareIcon from "assets/svgs/ShareIcon";
import ReportIcon from "assets/svgs/ReportIcon";
import image from "assets/images/team2.png";
import ChatIcon from "assets/svgs/ChatIcon";
import UserIcon from "assets/svgs/UserIcon";
import UserProfileNameIcon from "assets/svgs/UserProfileNameIcon";
import UserProfileMailIcon from "assets/svgs/UserProfileMailIcon";
import UserProfilePhoneIcon from "assets/svgs/UserProfilePhoneIcon";
import UserProfileJobIcon from "assets/svgs/UserProfileJobIcon";
import UserProfileWebIcon from "assets/svgs/UserProfileWebIcon";
import UserProfileLocationIcon from "assets/svgs/UserProfileLocationIcon";
import DollarIcon from "assets/svgs/DollarIcon";
import LocationIcon from "assets/svgs/LocationIcon";
import { useLocation } from "react-router-dom";
import imgsrc from "../../assets/images/no_image.png";
import { useGetUserPostList, useGetCities, useGetStates, useGetUserPostCount } from "hooks";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const UserProfileView = ({ t }) => {
  const { state } = useLocation(); // Access the passed state
  const userId = state?.userData;
  const [postList, setPostList] = useState([]);
  const [cityname, setCities] = useState([]);
  const [statename, setStates] = useState([]);
  const [userData, setUserData] = useState([]);

  const {
    refetch:refetch
  } = useGetUserPostCount(
    { user_id: userId },
    (res) => {
      setUserData(res?.data?.user_data);
    },
    (error) => {
      toast.error(error.message);
    }
  );


  const { refetch: adlistRefetch } = useGetUserPostList(
    { user_id: userData?.user_id },
    (res) => {
      setPostList(res?.data?.ads_list);
    },
    (error) => {
      toast.error(error.message);
    }
  );

  const { refetch: adCitylistRefetch } = useGetCities(
    (res) => {
      setCities(res?.data?.cities);
    },
    (error) => {
      toast.error(error.message);
    }
  );

  const { refetch: adStatelistRefetch } = useGetStates(
    (res) => {
      setStates(res?.data?.states);
    },
    (error) => {
      toast.error(error.message);
    }
  );

  useEffect(() => {
    adStatelistRefetch();
    adCitylistRefetch();
    adlistRefetch();
    refetch()
  }, [userData,userId]);

  const getStateName = (stateId) => {
    const state = statename.find((item) => item.state_id == stateId);
    return state ? state.name : "";
  };

  // Function to get the city name by ID
  const getCityName = (cityId) => {
    const city = cityname.find((item) => item.city_id == cityId);
    return city ? city.city : "";
  };

  const PrevArrow = ({ onClick }) => (
    <div className="custom-arrow prev-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );

  const NextArrow = ({ onClick }) => (
    <div className="custom-arrow next-arrow" onClick={onClick}>
      <FontAwesomeIcon icon={faChevronRight} />{" "}
      {/* Example icon for right arrow */}
    </div>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <FullScreenHeader></FullScreenHeader>
      <Container fluid className="p-5">
        <Row className="p-3 user-profile-main-container p-md-5">
          <Col
            md={12}
            className="d-flex align-items-center justify-content-between username-share"
          >
            <span className="user-profile-title">
              {userData?.businessDetails === null
                ? t("front_page.user_profile_title")
                : t("front_page.business_profile_title")}
            </span>
            <span className="d-flex align-items-center gap-3">
              <Button className="share-btn p-2 px-3">
                <ShareIcon />
                {t("front_page.share_icon")}
              </Button>
              <Button className="report-btn p-2 px-3">
                <ReportIcon /> {t("front_page.report_icon")}
              </Button>
            </span>
          </Col>
          <Col
            md={12}
            className="d-flex gap-5 mt-4 align-items-center user-info-main-box pb-5"
          >
            <div className="user-info">
              <span className="user-image-div">
                <img
                  src={
                    userData?.profile_image ? userData?.profile_image : imgsrc
                  }
                ></img>
              </span>
              <Button className="chat-btn px-4 py-2">
                <ChatIcon /> {t("front_page.chat_title")}
              </Button>
            </div>

            <div className="user-details">
              {/* name */}
              <div className="detail-row mb-4">
                <UserProfileNameIcon /> {userData?.full_name}
              </div>
              {/* email */}
              {userData?.businessDetails !== null &&
                userData?.businessDetails?.status === 1 && (
                  <div className="detail-row mb-4">
                    <UserProfileMailIcon /> {userData?.email}
                  </div>
                )}
              {/* contact */}
              {userData?.businessDetails !== null &&
                userData?.mobile_number && (
                  <div className="detail-row mb-4">
                    <UserProfilePhoneIcon /> {userData?.country_code_id}{" "}
                    {userData?.mobile_number}
                  </div>
                )}
              {userData?.businessDetails &&
                userData?.businessDetails?.status === 1 && (
                  <>
                    {/* job */}

                    <div className="detail-row mb-4">
                      <UserProfileJobIcon />
                      {userData?.businessDetails?.business_name}
                    </div>
                    {/* website */}
                    <div className="detail-row mb-4">
                      <UserProfileWebIcon />{" "}
                      {userData?.businessDetails?.web_url}
                    </div>
                    {/* Location */}
                    <div className="detail-row mb-4">
                      <UserProfileLocationIcon />
                      {userData?.businessDetails?.location}
                    </div>
                  </>
                )}
            </div>
          </Col>
          <Col md={12} className="mt-4">
            <span className="user-profile-title">
              {t("front_page.ad_post_title")}
            </span>
            <Row className="gap-4 gap-lg-5 gap-xl-3 card-main-row pt-2">
              {/* grid-card */}
              {postList.map((ads, index) => {
                return (
                  <Col className="grid-card" key={index}>
                    {ads?.advertisements_image?.length > 0 ? (
                      ads?.advertisements_image.length > 1 ? (
                        <Slider {...settings}>
                          {ads?.advertisements_image.map(
                            (imageValue, index) => (
                              <div className="img-div-multiimage" key={index}>
                                <img
                                  src={imageValue?.image_url}
                                  alt={`Preview ${index + 1}`}
                                />
                              </div>
                            )
                          )}
                        </Slider>
                      ) : (
                        <div className="img-div">
                          <img
                            src={ads.advertisements_image[0]?.image_url}
                            alt="Preview"
                          />
                        </div>
                      )
                    ) : (
                      <div className="img-div">
                        <img src={imgsrc} alt="Placeholder" />
                      </div>
                    )}
                    <div className="job-name mt-3">{ads?.post_title}</div>
                    {ads?.advertisements_variation?.length > 0 &&
                      ads?.advertisements_variation.map((attribute, indx) => {
                        const type = attribute?.attribute?.attribute_type;
                        if (type === 8 && type !== undefined) {
                          return (
                            <React.Fragment key={indx}>
                              <div className="job-price mt-3">
                                <DollarIcon></DollarIcon>
                                <span>{attribute?.value}</span>
                              </div>
                            </React.Fragment>
                          );
                        }
                      })}
                    <span className="loc-apply-wrapper mb-1">
                      <div className="location-name mt-2">
                        <LocationIcon></LocationIcon>
                        <span className="ms-1">
                          {getCityName(ads?.city) && getStateName(ads?.state)
                            ? `${getCityName(ads?.city)}, ${getStateName(
                                ads?.state
                              )}`
                            : getCityName(ads?.city)
                            ? getCityName(ads?.city)
                            : getStateName(ads?.state)}
                        </span>
                      </div>
                      {ads?.advertisements_variation.map((category, inx) => {
                        const type = category?.attribute?.attribute_type;
                        if (type === 7 && type !== undefined) {
                          return (
                            <React.Fragment key={inx}>
                              <Button className="apply-btn px-3">
                                {ads?.category_name === "For Sale"
                                  ? t("front_page.buy_button_text")
                                  : t("front_page.apply_button_text")}
                              </Button>
                            </React.Fragment>
                          );
                        }
                      })}
                    </span>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserProfileView;
