import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, "front_page.validation_name_min")
    .max(100, "front_page.validation_name_max")
    .required("front_page.validation_name_required"),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?<!\s)$/,
      "front_page.validation_email_invalid"
    )
    .email("front_page.validation_email_invalid")
    .required("front_page.validation_email_required"),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
      "front_page.validation_password_complexity"
    )
    .required("front_page.validation_password_required"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "front_page.validation_passwords_must_match"
    )
    .required("front_page.validation_confirm_password_required"),
});

export default validationSchema;
