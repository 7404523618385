// advertisementSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  advertisementData: {}, // Or `null`, depending on your need
  currentStepperStep: null,
};

const advertisementSlice = createSlice({
  name: 'advertisements',
  initialState,
  reducers: {
    updateAdvertisementData: (state, action) => {
      state.advertisementData = action.payload; // Direct mutation works thanks to Immer.js
    },
    updateCurrentStepperStep: (state, action) => {
      state.currentStepperStep = action.payload;
    },
  },
});

// Destructure the actions to export them
export const { updateAdvertisementData, updateCurrentStepperStep } = advertisementSlice.actions;

// Selector to access advertisement state
export const selectAdvertisementState = (state) => state.advertisements;

export default advertisementSlice.reducer;
