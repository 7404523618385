import React, { useState } from "react";
import {
  Container,
  Form,
  Button,
  InputGroup,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { Formik, Field, Form as FormikForm } from "formik";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import validationSchema from "./LoginValidation";
import "assets/scss/page/_auth.scss";
import header_logo from "assets/images/header_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useLoginUser, useRegisterUser } from "hooks";
import { loginSuccess, setToken } from "store/features/authSlice";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, provider } from "../../firebase/firebase";
import { setAuthToken } from "libs";
import { t } from "i18next";

const initialValues = {
  email: "",
  password: "",
};

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleGoogleLogin = async () => {
    try {
      await signInWithPopup(auth, provider)
        .then((res) => {
          if (res?._tokenResponse) {
            const { fullName, email, federatedId, photoUrl } =
              res._tokenResponse;
            const urlArray = federatedId.split("/");
            const loginData = {
              name: fullName,
              email: email,
              password: "", // Google login doesn't require a password
              gmail_id: urlArray[urlArray.length - 1],
              is_gmail_email_verify: 1,
              signup_type: 2,
              profile_image: photoUrl,
            };

            doLogin(loginData);
          }
        })
        .catch((error) => {
          console.log("Google login error: ", error);
        });
    } catch (error) {
      console.error("Error during Google login:", error);
    }
  };

  const { mutate: doLogin, isLoading } = useLoginUser((res) => {
    if (res.data.encoded_token) {
      dispatch(setToken({ encoded_token: res.data.encoded_token }));
      navigate("/otp-verification");
      toast.error(
        "Email not verified, please check your inbox and verify your email"
      );
    } else {
      const dataStore = {
        userData: res.data.user_data,
        isLoggedIn: true,
        accessToken: res.data.access_token,
      };
      dispatch(loginSuccess(dataStore));
      setTimeout(function () {
        setAuthToken(res.data.access_token);
        navigate("/dashboard");
      }, 1000);
      // toast.success(res.message);
    }
  });

  return (
    <Container className="d-flex justify-content-center align-items-center mt-4 login-container">
      <div className="login-content w-100">
        <Link to={"/"} className="pointer-event">
          <img src={header_logo} alt="Home logo" className="home-logo" />
        </Link>
        <div className="my-3">
          <h3>{t("front_page.login_page_sign_in_title")}</h3>
          <p>{t("front_page.login_page_sign_in_description")}</p>
        </div>
        <div>
          <Button
            onClick={handleGoogleLogin}
            variant="transparent"
            className="text-light  w-100 gmail-login"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="25"
              height="25"
              viewBox="0 0 48 48"
            >
              <path
                fill="#4caf50"
                d="M45,16.2l-5,2.75l-5,4.75L35,40h7c1.657,0,3-1.343,3-3V16.2z"
              ></path>
              <path
                fill="#1e88e5"
                d="M3,16.2l3.614,1.71L13,23.7V40H6c-1.657,0-3-1.343-3-3V16.2z"
              ></path>
              <polygon
                fill="#e53935"
                points="35,11.2 24,19.45 13,11.2 12,17 13,23.7 24,31.95 35,23.7 36,17"
              ></polygon>
              <path
                fill="#c62828"
                d="M3,12.298V16.2l10,7.5V11.2L9.876,8.859C9.132,8.301,8.228,8,7.298,8h0C4.924,8,3,9.924,3,12.298z"
              ></path>
              <path
                fill="#fbc02d"
                d="M45,12.298V16.2l-10,7.5V11.2l3.124-2.341C38.868,8.301,39.772,8,40.702,8h0 C43.076,8,45,9.924,45,12.298z"
              ></path>
            </svg>{" "}
            {t("front_page.login_page_sign_in_with_gmail_txt")}
          </Button>
          <div className="separator my-3">
            <span>OR</span>
          </div>
        </div>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              doLogin(values);
            }}
          >
            {({ errors, touched }) => (
              <FormikForm className="login-form" noValidate>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {" "}
                    {t("front_page.login_page_email_label")}
                  </Form.Label>
                  <Field
                    as={Form.Control}
                    type="email"
                    name="email"
                    placeholder={t("front_page.login_page_email_placeholder")}
                    autoComplete="off"
                    isInvalid={!!errors.email && touched.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {t(errors.email)}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("front_page.login_page_password_label")}
                  </Form.Label>
                  <InputGroup>
                    <Field
                      as={FormControl}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder={t(
                        "front_page.login_page_password_placeholder"
                      )}
                      className="login-password w-100"
                      autoComplete="off"
                      isInvalid={!!errors.password && touched.password}
                    />
                    <span className="password-icon">
                      <InputGroup.Text
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </InputGroup.Text>
                    </span>
                    <Form.Control.Feedback type="invalid">
                      {t(errors.password)}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <div className="text-end">
                  <Link to={"/forgot-password"} className="hover-txt">
                    {t("front_page.login_page_forgot_password_txt")}
                  </Link>
                </div>

                <Button
                  type="submit"
                  variant="primary"
                  className="w-100 my-3 register-btn"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      {t("front_page.login_page_loading_txt")}
                    </>
                  ) : (
                    `${t("front_page.login_page_sign_in_btn_txt")}`
                  )}
                </Button>
                <div className="text-center mb-4">
                  <span>
                    {t("front_page.login_page_create_account_txt")}{" "}
                    <Link to={"/register"} className="hover-txt">
                      {t("front_page.login_page_create_now")}
                    </Link>
                  </span>
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
};

export default LoginPage;
