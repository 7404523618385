import {  AttributeService } from "api";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
const onDefaultError = (error) => {
  toast.error(error.message);
};
const useAttributeList = (params, onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["attribute_list", [params]],
    () => {
      return AttributeService.attributeList({ params });
    },
    {
      onSuccess,
      onError,
    }
  );
};

export { useAttributeList };
