import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  cardOwner: Yup.string()
    .required("Card owner name is required")
    .min(2, "Name is too short - should be 2 chars minimum.")
    .max(50, "Name is too long - should be 50 chars maximum."),
});

export default validationSchema;
