import React, { useEffect, useState } from "react";
import "../../../assets/scss/page/_full_screen_left_menu.scss";
import { Accordion, Button, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useGetParentCategoryList } from "hooks";

function FullScreenLeftMenu({
  getSettingData,
  children,
  sharedVariable,
  setSharedVariable,
  childCategoryName,
  checkedItems,
  handleCheckboxChange,
  handleAttributeCheckboxChange,
  attribute,
  checkedAttributes,
  handleReset,
  handleApply,
  parentCategory,
  childCategory,
  tempSelectedCategory,
}) {
  const closeFilterMenu = () => {
    setSharedVariable("close");
  };
  const { t } = useTranslation();
  const [parentCategoryName, setParentCAtegoryName] = useState("");
  const [showAll, setShowAll] = useState(false);

  const formattedParentData = [];
  //parent category name
  const { data: parent_category_list } = useGetParentCategoryList();
  if (parent_category_list?.data) {
    parent_category_list.data.forEach((item) => {
      formattedParentData.push({
        value: item.category_id,
        label: item.category_name,
      });
    });
  }

  useEffect(() => {
    if (parentCategory) {
      const selectedCategory = formattedParentData.find(
        (item) => item.value == parentCategory
      );
      if (selectedCategory) {
        setParentCAtegoryName(selectedCategory.label); // Pass the selected category name to the parent component
      }
    }
  }, [parentCategory, parentCategoryName, formattedParentData]);

  const filteredOptions = childCategoryName?.filter((option) => option.value);

  // Slice the list to show the first 7 items, and show the rest when `showAll` is true
  const optionsToDisplay = showAll
    ? filteredOptions
    : filteredOptions.slice(0, 7);

  let uniqueAttributes;
  if (Array.isArray(attribute)) {
    uniqueAttributes = attribute?.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.attribute_id === value.attribute_id)
    );
  }
  return (
    <>
      <Container
        fluid
        className={`m-0 fullscreen-left-menu ${
          sharedVariable == "open" ? "open" : "close"
        }`}
      >
        <Row className="wrapper-left-menu m-0">
          <Accordion defaultActiveKey="0" className="ps-0 accordion-wrap">
            <Accordion.Item eventKey="0" className="accordion_item p-0">
              <Accordion.Header className="px-0">
                {t("front_page.filter_by", { parentCategoryName })}
              </Accordion.Header>
              <Accordion.Body
                className="px-0 mx-1 mt-0 pt-2 filter-by-job"
                style={{ background: "#red" }}
              >
                {/* value 1 */}
                {optionsToDisplay // Filter out the option with an empty value
                  .map((option, index) => (
                    <div className="d-flex justify-content-between mb-1">
                      <Form.Check
                        inline
                        key={index}
                        label={option.label}
                        name="group1"
                        type="checkbox"
                        className="custom-check"
                        value={option.value}
                        checked={checkedItems.includes(option?.value)} // Sync checkbox state with parent
                        onChange={() => handleCheckboxChange(option.value)}
                        style={{ fontSize: "12px" }}
                      />
                      <span
                        className="value-count"
                        style={{ wordBreak: "initial", fontSize: "14px" }}
                      >
                        {option?.post_count}
                      </span>
                    </div>
                  ))}

                {filteredOptions?.length > 7 && !showAll && (
                  <div className="text-end" style={{ textAlign: "end" }}>
                    <button
                      onClick={() => setShowAll(true)}
                      className="btn btn-link text-right show-more-less-btn"
                      style={{ color: "#ffffff" }}
                    >
                      {t("front_page.read_more_text")}
                    </button>
                  </div>
                )}

                {filteredOptions?.length > 7 && showAll && (
                  <div className="text-end" style={{ textAlign: "end" }}>
                    <Button
                      onClick={() => setShowAll(false)}
                      className="btn-link text-right show-more-less-btn"
                      style={{ color: "#ffffff" }}
                    >
                      {t("front_page.show_less_text")}
                    </Button>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
            {uniqueAttributes?.length > 0 && (
              <Accordion.Item eventKey="1" className="accordion_item p-0">
                <Accordion.Header className="px-0">
                  {t("front_page.employement_type")}
                </Accordion.Header>
                <Accordion.Body className="px-0 ms-1 mt-0 pt-2">
                  {/* value 1 */}

                  {uniqueAttributes.map((option, index) => (
                    <div className="d-flex justify-content-between align-items-center mb-1">
                      <Form.Check
                        inline
                        key={option?.attribute_id}
                        label={option.attributeCategory?.attribute_name}
                        name="group2"
                        type="checkbox"
                        className="custom-check"
                        value={option.attribute_id}
                        checked={checkedAttributes.includes(
                          option?.attribute_id
                        )}
                        onChange={() =>
                          handleAttributeCheckboxChange(option?.attribute_id)
                        }
                        style={{ fontSize: "12px" }}
                      />
                      {/* <span className='value-count'>350</span> */}
                    </div>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
          <div className=" align-items-center filter-btn-wrapper">
            <Button
              className="reset-btn"
              onClick={() => {
                handleReset();
              }}
            >
              {t("front_page.reset_button_text")}
            </Button>
            <Button
              className="apply-btn"
              onClick={() => {
                handleApply();
              }}
            >
              {t("front_page.apply_button_text")}
            </Button>
          </div>

          <span className="close-btn" onClick={closeFilterMenu}>
            close
          </span>
        </Row>
      </Container>
    </>
  );
}

export default FullScreenLeftMenu;
