import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PropTypes from "prop-types";


const PostAdsList = React.lazy(() => import("./PostAdsList"));

const PostListRoute = ({ getSettingData, t }) => {
  return (
    <Routes>
      <Route
        exact
        path={`/post-list`}
        element={<PostAdsList getSettingData={getSettingData} t={t} />}
      />
      <Route exact path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};
PostListRoute.propTypes = {
  t: PropTypes.func,
};
export default PostListRoute;
