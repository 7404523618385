import React from "react";
import ReactDOM from "react-dom/client";
import "index.css";
import App from "pages/App";
import reportWebVitals from "libs/reportWebVitals";
import { SearchProvider } from "context/SearchContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SearchProvider>
      <App />
    </SearchProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
