import React from 'react'

function UserProfileMailIcon() {
    return (
        <>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.66665 28.3337C4.88748 28.3337 4.22047 28.0562 3.6656 27.5014C3.11074 26.9465 2.83331 26.2795 2.83331 25.5003V8.50033C2.83331 7.72116 3.11074 7.05414 3.6656 6.49928C4.22047 5.94442 4.88748 5.66699 5.66665 5.66699H28.3333C29.1125 5.66699 29.7795 5.94442 30.3344 6.49928C30.8892 7.05414 31.1666 7.72116 31.1666 8.50033V25.5003C31.1666 26.2795 30.8892 26.9465 30.3344 27.5014C29.7795 28.0562 29.1125 28.3337 28.3333 28.3337H5.66665ZM17 18.417L5.66665 11.3337V25.5003H28.3333V11.3337L17 18.417ZM17 15.5837L28.3333 8.50033H5.66665L17 15.5837ZM5.66665 11.3337V8.50033V25.5003V11.3337Z" fill="white" />
            </svg>
        </>
    )
}

export default UserProfileMailIcon