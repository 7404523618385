import React, { useState } from 'react'
import { Button, Col, Container, Form, Nav, Row, Tab } from 'react-bootstrap';
import '../../assets/scss/page/_full_screen_tabs.scss';
import Select from 'react-select';
import LocationIcon from 'assets/svgs/LocationIcon';
import DollarIcon from 'assets/svgs/DollarIcon';
import SearchIcon from 'assets/svgs/SearchIcon';
import imgsrc from '../../assets/images/team3.png';
import ShareIcon from 'assets/svgs/ShareIcon';
import LikeIcon from 'assets/svgs/LikeIcon';

function ViewAdsTabs({ setSharedVariable }) {
    const handleButtonClick = () => {
        // Update the shared variable as needed
        setSharedVariable('open');
    };
    const [activeTab, setActiveTab] = useState('1'); // Set the default active tab

    const handleSelectTab = (eventKey) => {
        setActiveTab(eventKey);
    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent", // Dark background
            borderColor: "#4d4d4d", // Dark border
            borderWidth: "1px",
            borderRadius: "10px",
            minWidth: "160px",
            height: "40px",
            boxShadow: "none", // Remove shadow
            color: "#fff", // White text
            "&:hover": {
                borderColor: "#2e2d2d", // Lighter border on hover
                color: "#fff", // White text
            },
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "transparent", // Dark background for menu
            color: "#fff", // White text
            // Hide scrollbar
            overflowY: "hidden", // Hide vertical scrollbar
            maxHeight: "190px",
        }),
        menuList: (provided) => ({
            ...provided,

            "::-webkit-scrollbar": {
                width: "4px",
                height: "2px",
            },
            "::-webkit-scrollbar-track": {
                background: "#f1f1f1",
            },
            "::-webkit-scrollbar-thumb": {
                background: "#0A5640",
            },
            "::-webkit-scrollbar-thumb:hover": {
                background: "#555",
            },
            overflowX: "hidden",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? "#2e2d2d"
                : state.isFocused
                    ? "#2e2d2d"
                    : "#2e2d2d", // Dark background for options
            color: "#fff", // White text

            "&:hover": {
                backgroundColor: "#2e2d2d", // Lighter background on hover
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#ffff", // White text for selected value
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "rgb(177, 177, 177)", // White text
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            backgroundColor: "transparent", // Dark indicator separator
            // color: "#0000",
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: "#fff", // White dropdown indicator
        }),
        input: (provided) => ({
            ...provided,
            color: "#fff", // White text color in the search input
        }),
    };

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ];

    return (
        <>
            <Container className='view_ad_tab_main p-4'>
                {/* <Row> */}
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                    <Row className="mt-4">
                        <Col sm={12} md={12} xl={12} className='d-flex flex-column flex-lg-row align-items-start justify-content-between align-items-lg-center'>
                            <Nav
                                className=" profile-bar mb-3 mb-xl-0 justify-content-between"
                                onSelect={handleSelectTab}>
                                <div className='d-flex flex-lg-row align-items-start  justify-content-xl-start justify-content-evenly justify-content-xl-start'>
                                    <Nav.Item className=''>
                                        <Nav.Link
                                            eventKey="1"
                                            className={`mb-0 view_tab tabname ${activeTab === '1' ? 'tab-active-class' : ''}`}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3 11V3H11V11H3ZM3 21V13H11V21H3ZM13 11V3H21V11H13ZM13 21V13H21V21H13ZM5 9H9V5H5V9ZM15 9H19V5H15V9ZM15 19H19V15H15V19ZM5 19H9V15H5V19Z" fill="white" />
                                            </svg>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="2"
                                            className={`mb-0 view_tab tabname ${activeTab === '2' ? 'tab-active-class' : ''}`}>
                                            <svg width="26" height="26" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 9V7H21V9H7ZM7 13V11H21V13H7ZM7 17V15H21V17H7ZM4 9C3.71667 9 3.47917 8.90417 3.2875 8.7125C3.09583 8.52083 3 8.28333 3 8C3 7.71667 3.09583 7.47917 3.2875 7.2875C3.47917 7.09583 3.71667 7 4 7C4.28333 7 4.52083 7.09583 4.7125 7.2875C4.90417 7.47917 5 7.71667 5 8C5 8.28333 4.90417 8.52083 4.7125 8.7125C4.52083 8.90417 4.28333 9 4 9ZM4 13C3.71667 13 3.47917 12.9042 3.2875 12.7125C3.09583 12.5208 3 12.2833 3 12C3 11.7167 3.09583 11.4792 3.2875 11.2875C3.47917 11.0958 3.71667 11 4 11C4.28333 11 4.52083 11.0958 4.7125 11.2875C4.90417 11.4792 5 11.7167 5 12C5 12.2833 4.90417 12.5208 4.7125 12.7125C4.52083 12.9042 4.28333 13 4 13ZM4 17C3.71667 17 3.47917 16.9042 3.2875 16.7125C3.09583 16.5208 3 16.2833 3 16C3 15.7167 3.09583 15.4792 3.2875 15.2875C3.47917 15.0958 3.71667 15 4 15C4.28333 15 4.52083 15.0958 4.7125 15.2875C4.90417 15.4792 5 15.7167 5 16C5 16.2833 4.90417 16.5208 4.7125 16.7125C4.52083 16.9042 4.28333 17 4 17Z" fill="white" />
                                            </svg>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            eventKey="3"
                                            className={`mb-0 view_tab tabname ${activeTab === '3' ? 'tab-active-class' : ''}`}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 21L9 18.9L4.35 20.7C4.01667 20.8333 3.70833 20.7958 3.425 20.5875C3.14167 20.3792 3 20.1 3 19.75V5.75C3 5.53333 3.0625 5.34167 3.1875 5.175C3.3125 5.00833 3.48333 4.88333 3.7 4.8L9 3L15 5.1L19.65 3.3C19.9833 3.16667 20.2917 3.20417 20.575 3.4125C20.8583 3.62083 21 3.9 21 4.25V18.25C21 18.4667 20.9375 18.6583 20.8125 18.825C20.6875 18.9917 20.5167 19.1167 20.3 19.2L15 21ZM14 18.55V6.85L10 5.45V17.15L14 18.55ZM16 18.55L19 17.55V5.7L16 6.85V18.55ZM5 18.3L8 17.15V5.45L5 6.45V18.3Z" fill="white" />
                                            </svg>
                                        </Nav.Link>
                                    </Nav.Item>
                                </div>
                                <Button className='filters-button ms-4' onClick={handleButtonClick}>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M4.5 7H19.5M7 12H17M10 17H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    Filters
                                </Button>
                            </Nav>
                            <div className='right-sec mt-3 mt-md-0'>
                                {/* <Button className='filters-button'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.5 7H19.5M7 12H17M10 17H14" stroke="white" strokeWidth="1.5" strokeLinecap="round" stroke-linejoin="round" />
                                        </svg>
                                        Filters
                                    </Button> */}
                                <Form.Group
                                    controlId="search"
                                    className="filter-field-space filter-status position-relative search-group d-flex align-items-center"
                                >
                                    <Form.Control
                                        placeholder="Search"
                                        className="filter-column search-box form-field"
                                    // onKeyUp={handleSearch}
                                    // onChange={handleSearch}
                                    // value={searchText}
                                    />
                                    <span className="search-btn">
                                        <SearchIcon></SearchIcon>
                                    </span>
                                </Form.Group>
                                <Select styles={customStyles} options={options}></Select>
                            </div>
                        </Col>
                        <Col sm={12} md={12} xl={12} className="px-4 mt-lg-5 mt-xl-0">
                            <Tab.Content className="mt-4 mt-lg-5">

                                {/* grid-view */}
                                <Tab.Pane eventKey="1">
                                    <Row className='gap-4 gap-lg-5 gap-xl-3 card-main-row'>
                                        {/* grid-card */}
                                        <Col className='grid-card'>
                                            <div className='img-div'>
                                                <img src={imgsrc}></img>
                                            </div>
                                            <div className='job-name mt-3'>Administrative Assistant</div>
                                            <div className='job-price mt-3'>
                                                <DollarIcon></DollarIcon>
                                                <span className='ms-1'>10000</span>/<span>Month</span>
                                            </div>
                                            <span className='loc-apply-wrapper mb-1'>
                                                <div className='location-name mt-2'>
                                                    <LocationIcon></LocationIcon>
                                                    <span className='ms-1'>Auburn, AL</span>
                                                </div>
                                                <Button className='apply-btn px-3'>Apply</Button>
                                            </span>
                                        </Col>

                                    </Row>
                                </Tab.Pane>

                                {/* list view */}
                                <Tab.Pane eventKey="2">
                                    {/* single list item */}
                                    <div className='list-view-row d-flex justify-content-between align-items-center mb-3'>
                                        <div className='d-flex align-items-center gap-3 left-section-detail'>
                                            <span className='like-btn'>
                                                <LikeIcon></LikeIcon>
                                            </span>
                                            <span className='job-title '>asistente de oficina</span>
                                            <span className='btn-row'>
                                                <Button className='view-btn'>view</Button>
                                                <Button className='apply-btn ms-2'>Apply</Button>
                                            </span>
                                        </div>
                                        <div className='right-section-detail'>
                                            <span className='pricing'>
                                                <DollarIcon></DollarIcon> <span className=''>22000</span>/<span>Month</span>
                                            </span>
                                            <span className='share-btn'>
                                                <ShareIcon></ShareIcon>
                                            </span>
                                            <span className='location-span'>
                                                <LocationIcon></LocationIcon> <span className=''>Auburn/AL</span>
                                            </span>
                                        </div>

                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="3">
                                    hello
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                {/* </Row> */}
            </Container>
        </>
    )
}

export default ViewAdsTabs