import React from 'react'

function LikeIcon() {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.99999 17.4997L8.79166 16.4163C7.38888 15.1525 6.22916 14.0622 5.31249 13.1455C4.39582 12.2288 3.66666 11.4059 3.12499 10.6768C2.58332 9.94759 2.20485 9.27745 1.98957 8.66634C1.7743 8.05523 1.66666 7.43023 1.66666 6.79134C1.66666 5.48579 2.10416 4.39551 2.97916 3.52051C3.85416 2.64551 4.94443 2.20801 6.24999 2.20801C6.97221 2.20801 7.65971 2.36079 8.31249 2.66634C8.96527 2.9719 9.52777 3.40245 9.99999 3.95801C10.4722 3.40245 11.0347 2.9719 11.6875 2.66634C12.3403 2.36079 13.0278 2.20801 13.75 2.20801C15.0555 2.20801 16.1458 2.64551 17.0208 3.52051C17.8958 4.39551 18.3333 5.48579 18.3333 6.79134C18.3333 7.43023 18.2257 8.05523 18.0104 8.66634C17.7951 9.27745 17.4167 9.94759 16.875 10.6768C16.3333 11.4059 15.6042 12.2288 14.6875 13.1455C13.7708 14.0622 12.6111 15.1525 11.2083 16.4163L9.99999 17.4997ZM9.99999 15.2497C11.3333 14.0552 12.4305 13.0309 13.2917 12.1768C14.1528 11.3226 14.8333 10.5795 15.3333 9.94759C15.8333 9.31565 16.1805 8.75315 16.375 8.26009C16.5694 7.76704 16.6667 7.27745 16.6667 6.79134C16.6667 5.95801 16.3889 5.26356 15.8333 4.70801C15.2778 4.15245 14.5833 3.87467 13.75 3.87467C13.0972 3.87467 12.493 4.0587 11.9375 4.42676C11.3819 4.79481 11 5.26356 10.7917 5.83301H9.20832C8.99999 5.26356 8.61804 4.79481 8.06249 4.42676C7.50693 4.0587 6.90277 3.87467 6.24999 3.87467C5.41666 3.87467 4.72221 4.15245 4.16666 4.70801C3.6111 5.26356 3.33332 5.95801 3.33332 6.79134C3.33332 7.27745 3.43055 7.76704 3.62499 8.26009C3.81943 8.75315 4.16666 9.31565 4.66666 9.94759C5.16666 10.5795 5.84721 11.3226 6.70832 12.1768C7.56943 13.0309 8.66666 14.0552 9.99999 15.2497Z" fill="white" />
            </svg>
        </>
    )
}

export default LikeIcon