import React, { useState } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import flag from "assets/images/usa-flag.png";
import { useGetCities, useGetStates } from "hooks";
import { toast } from "react-toastify";

const CustomToggle = ({ children, onClick, isExpanded }) => (
  <Row className="text-light d-flex justify-content-center align-items-center">
    <Col
      md={9}
      className="text-center toggle-header"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      {children}
    </Col>
    <Col
      className="accordion-btn"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <FontAwesomeIcon
        icon={isExpanded ? faMinus : faPlus}
        className="ms-2 toggle-header"
      />
    </Col>
  </Row>
);

const RightSidebar = ({ setCity }) => {
  const [expandedKey, setExpandedKey] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  // Fetch states
  const { data: stateData, isLoading: loadingStates } = useGetStates(
    (data) => {
      setStates(data?.data.states || []);
    },
    (error) => {
      toast.error(error.message);
    }
  );

  // Fetch cities
  const { data: cityData, isLoading: loadingCities } = useGetCities(
    (data) => {
      setCities(data?.data.cities || []);
    },
    (error) => {
      toast.error(error.message);
    }
  );

  const handleToggle = (key) => {
    setExpandedKey(expandedKey === key ? null : key);
  };

  const handleCanvasButtonClick = () => {
    const elements = document.getElementsByClassName("offcanvas");
    if (elements.length > 0) {
      const element = elements[0];
      element.classList.remove("show");
      const toggle = document.getElementsByClassName("right-toggle");
      toggle[0].click();
    } else {
      console.log("No elements found with the class 'offcanvas'.");
    }
  };

  const handleCityClick = async (city_id, name, state_id) => {
    const city_data = {
      city_id: city_id,
      name: name,
      state_id: state_id,
    };
    await getLatLngByCity(name);

    setCity(city_data);
  };

  const getLatLngByCity = async (cityName) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
          cityName
        )}&format=json&addressdetails=1`
      );
      const data = await response.json();

      if (data.length > 0) {
        const { lat, lon } = data[0];
        const coordinates = {
          latitude: lat,
          longitude: lon,
        };

        localStorage.setItem("citylatlog", JSON.stringify(coordinates));
        return { lat: parseFloat(lat), lon: parseFloat(lon) };
      } else {
        throw new Error("City not found");
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      return null; // Handle error
    }
  };

  return (
    <div className="right-sidebar bg-theme text-white py-3 position-relative sticky-right-sidebar">
      <div>
        <button
          type="button"
          className="btn-close canvas-btn"
          aria-label="Close"
          onClick={handleCanvasButtonClick}
        ></button>
        <Button
          variant="dark"
          className="mb-3 btn-add states-container states-container-responsive"
        >
          <img src={flag} alt="Flag" className="flag-img me-2" />
          US States
        </Button>
        <Accordion className="states-container">
          {loadingStates ? (
            <p>Loading states...</p>
          ) : (
            states.map((state) => {
              const filteredCities = cities.filter(
                (city) => city.state_id === state.state_id
              );
              const hasCities = filteredCities.length > 0;

              return (
                <Card
                  key={state.state_id}
                  className="bg-theme my-2 accordion-menu"
                >
                  <Card.Header>
                    {hasCities ? (
                      <CustomToggle
                        onClick={() => handleToggle(state.state_id)}
                        isExpanded={expandedKey === state.state_id}
                      >
                        {state.name || "Unnamed State"}
                      </CustomToggle>
                    ) : (
                      <Row className="text-light d-flex justify-content-center align-items-center">
                        <Col md={12} className="text-center text-custom">
                          {state.name || "Unnamed State"}
                        </Col>
                      </Row>
                    )}
                  </Card.Header>
                  {hasCities && (
                    <Accordion.Collapse
                      eventKey={state.state_id}
                      in={expandedKey === state.state_id}
                    >
                      <Card.Body>
                        <ul className="list-unstyled category-list text-light">
                          {filteredCities.map((city) => (
                            <li
                              key={city.city_id}
                              onClick={() =>
                                handleCityClick(
                                  city.city_id,
                                  city.city,
                                  state.state_id
                                )
                              }
                            >
                              {city.city}
                            </li>
                          ))}
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  )}
                </Card>
              );
            })
          )}
        </Accordion>
      </div>
    </div>
  );
};

export { RightSidebar };
