import axios from "axios";
import { createBrowserHistory } from "history";

import { API_URL } from "config";
const myHistory = createBrowserHistory({ window });

const client = axios.create({
  baseURL: `${API_URL}/`,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
  },
});
client.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (!error.response) {
      throw new Error("Error Connection");
    }
    if (error.response.status === 401 || error.response.status === 414) {
      window.localStorage.clear();
      myHistory.replace(`/`);
      window.localStorage.isShowingError = error.response.data.message;
      window.location.reload();
    }

    return Promise.reject(error.response.data);
  }
);

export default client;

export const setAuthToken = (token) => {
  client.defaults.headers.common["Authorization"] = "";
  delete client.defaults.headers.common["Authorization"];
  if (token) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
};
export const setAuthType = (type) => {
  client.defaults.headers.common["AuthType"] = "";
  delete client.defaults.headers.common["AuthType"];
  if (type) {
    client.defaults.headers.common["AuthType"] = type;
  }
};
