import React, { useEffect, useState, useTransition } from "react";
import { Button, Nav } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faSearch } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import header_logo from "assets/images/header_logo.png";
import {
  useGetSettingData,
  useGetAllDataBySearchFilter,
  useGetAllCategoryNameList,
  useGetLastAds,
} from "hooks";
import { useTranslation } from "react-i18next";
import { useSearch } from "context/SearchContext";
import Select from "react-select";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

// Sidebar Component with Formik
const Sidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user);
  const [currentView, setCurrentView] = useState("desktop");
  const [isMobile, setIsMobile] = useState(false);
  const [tabView, setTabView] = useState(false);
  const { setSearchTerm } = useSearch();
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [search, setSearch] = useState(
    localStorage.searchItemPost !== undefined &&
      localStorage.searchItemPost !== ""
      ? JSON.parse(localStorage.searchItemPost).search
      : ""
  );

  const [isFocused, setIsFocused] = useState(false);
  const location = useLocation();
  // This will store the previous location
  const [prevLocation, setPrevLocation] = useState(null);
  const [previousAds, setPreviousAds] = useState([]);
  const { mutate: doGetLastAds, isLoading: gettingAds } = useGetLastAds(
    (res) => {
      if (Object.keys(res?.data).length > 0) {
        setPreviousAds([res?.data]);
      }
    },
    (error) => {
      toast.error(error.message);
    }
  );

  useEffect(() => {
    if (user?.user?.user_id != undefined) {
      doGetLastAds({ user_id: user?.user?.user_id });
    }
  }, []);
  useEffect(() => {
    // Function to handle media query changes
    const handleMediaChange = () => {
      const isMobile = window.matchMedia("(max-width: 575px)").matches;
      const isTablet = window.matchMedia(
        "(min-width: 576px) and (max-width: 991px)"
      ).matches;

      if (isMobile) {
        setIsMobile(true);
        setCurrentView("phone");
        setTabView(false);
      } else if (isTablet) {
        setIsMobile(false);
        setCurrentView("tablet");
        setTabView(true);
      } else {
        setIsMobile(false);
        setCurrentView("desktop");
        setTabView(false);
      }
    };

    // Initial check
    handleMediaChange();

    // Add event listeners for media queries
    window.addEventListener("resize", handleMediaChange);

    // Cleanup event listeners on component unmount
    return () => window.removeEventListener("resize", handleMediaChange);
  }, []);

  useEffect(() => {
    let searchItemPost = {
      search: search,
    };
    localStorage.searchItemPost = JSON.stringify(searchItemPost);
    setTimeout(() => {
      refetchCategoryList();
    }, 100);
  }, [search]);

  useGetAllDataBySearchFilter(
    {
      search_text: search,
    },
    (res) => {
      // setData(res.data.users_list);
    }
  );
  const { data: categories, refetch: refetchCategoryList } =
    useGetAllCategoryNameList({
      search_name: search,
    });

  const { data: getSettingData } = useGetSettingData(
    "/about",
    () => {},
    (error) => {
      if (error.data.redirect == true) {
        console.log("Error occurred: ", error.message);
      }
    }
  );
  const handleCanvasButtonClick = () => {
    // // Get the collection of elements with the class name "offcanvas"
    const elements = document.getElementsByClassName("offcanvas");

    // Check if there are any elements with this class
    if (elements.length > 0) {
      // Access the first element in the collection
      const element = elements[0];

      // Remove the "show" class from the first element
      element.classList.remove("show");
      const toggle = document.getElementsByClassName("left-toggle");
      toggle[0].click();
    } else {
      console.log("No elements found with the class 'offcanvas'.");
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setSearchTerm(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key == "Enter") {
      // Handle the Enter key
      localStorage.setItem("category", JSON.stringify(selectedCategory));
      navigate(`/post-advertisement/post-list`, {
        state: { selectedCategory },
      });
    }
  };

  useEffect(() => {
    if (prevLocation && location.key === prevLocation.key) {
      setSearch("");
      setSearchTerm("");
    }
    // Update the prevLocation with the current location
    setPrevLocation(location);
  }, [location, prevLocation]);

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
    setSearch(category.category_name);
    setIsFocused(false);
    localStorage.setItem("category", JSON.stringify(category));
    navigate(`/post-advertisement/post-list`, { state: { category } });
  };

  const handleCreatePostBtn = async () => {
    if (user?.user?.user_id != undefined) {
      await doGetLastAds({ user_id: user?.user?.user_id });
      if (!gettingAds) {
        if (previousAds.length > 0) {
          navigate("/ads/create-ads", {
            state: {
              subcategoryIds: previousAds[0]?.sub_category_id,
              parentCategory: previousAds[0]?.category_id,
              advertisementId: previousAds[0]?.advertisement_id,
              step: previousAds[0]?.step,
            },
          });
        } else {
          navigate("/ads/select-category");
        }
      }
    } else {
      navigate("/login");
    }
  };
  return (
    <div className="sidebar bg-theme text-white position-relative p-3">
      <button
        type="button"
        class="btn-close canvas-btn"
        aria-label="Close"
        onClick={handleCanvasButtonClick}
      ></button>
      <div className="sidebar-top mb-4">
        <h2 className="text-center mb-4">
          <Link
            to="/"
            className="text-white text-decoration-none"
            onClick={handleCanvasButtonClick}
          >
            <img
              src={
                getSettingData?.data.home_page_general_home_page_logo ||
                header_logo
              }
              alt="Header logo"
              className="home-logo"
            />
          </Link>
        </h2>
        <div className="post-add-btn mb-3">
          <Button
            variant="dark"
            className="mb-3 w-100 btn-add"
            onClick={handleCreatePostBtn}
          >
            <FontAwesomeIcon icon={faPenToSquare} className="me-1" />
            {t("front_page.sidebar_post_add_btn_txt")}
          </Button>
        </div>
        {currentView === "desktop" && (
          <>
            <div
              className="d-flex"
              style={{
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="input-group position-relative">
                <input
                  type="text"
                  id="search"
                  name="search"
                  placeholder={t("front_page.sidebar_search_placeholder")}
                  value={search}
                  className="form-control text-light"
                  onChange={(e) => handleSearch(e)}
                  onFocus={() => setIsFocused(true)}
                  onKeyDown={handleKeyDown}
                  // onBlur={() => setIsFocused(false)}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  className="search-icon position-absolute"
                />
              </div>
              {/* comment code when search text return category and subcategoryname */}
              {/* {search && isFocused && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    background: "#212529",
                    borderRadius: "4px",
                    border: "1px solid rgba(255, 255, 255, 0.1)", // Optional border
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Optional shadow for depth
                    zIndex: 100,
                    width: "100%",
                  }}
                >
                  {categories?.data?.length > 0 &&
                    categories?.data?.map((category) => (
                      <span
                        key={category.category_id}
                        style={{
                          paddingTop: 10,
                          paddingRight: 10,
                          paddingLeft: "1rem",
                          cursor: "pointer",
                          textAlign: "start",
                          width: "100%",
                        }}
                        onClick={() => handleSelectCategory(category)} // Select category on click
                        onMouseOver={() => setIsFocused(true)}
                        onMouseLeave={() => setIsFocused(false)}
                        // e// Hover effect
                      >
                        {category.category_name}
                      </span>
                    ))}
                  {!categories?.data.length && (
                    <span
                      key={123}
                      style={{
                        paddingTop: 10,
                        paddingRight: 10,
                        paddingLeft: "1rem",
                        cursor: "pointer",
                        textAlign: "start",
                        width: "100%",
                      }}
                      onMouseOver={() => setIsFocused(true)}
                      onMouseLeave={() => setIsFocused(false)}
                      // e// Hover effect
                    >
                      No Data Found
                    </span>
                  )}
                </div>
              )} */}
            </div>
          </>
        )}
      </div>

      <div className="sidebar-bottom">
        {/* Navigation Links */}
        <Nav className="flex-column list-unstyled link-lists">
          <Link
            to={"/about"}
            className="text-white nav-link-custom"
            onClick={handleCanvasButtonClick}
          >
            {t("front_page.sidebar_link_about")}
          </Link>
          <Link
            to={"/contact"}
            className="text-white nav-link-custom"
            onClick={handleCanvasButtonClick}
          >
            {t("front_page.sidebar_link_contact_us")}
          </Link>
          <Link
            to={"/faq"}
            className="text-white nav-link-custom"
            onClick={handleCanvasButtonClick}
          >
            {t("front_page.sidebar_link_help")}
          </Link>
          <Link
            to={"/page/privacy-policy"}
            className="text-white nav-link-custom"
            onClick={handleCanvasButtonClick}
          >
            {t("front_page.sidebar_link_privacy")}
          </Link>
          <Link
            to={"/page/terms-conditions"}
            className="text-white nav-link-custom last-row"
            onClick={handleCanvasButtonClick}
          >
            {t("front_page.sidebar_link_term")}
          </Link>
        </Nav>
      </div>
    </div>
  );
};

export { Sidebar };
