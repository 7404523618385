import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import validationSchema from "./ContactValidation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useGetCountryCodeList, useSendContactQuery } from "hooks";
import { toast } from "react-toastify";
import Select from "react-select";

const ContactUs = ({ t, getSettingData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [options, setOptions] = useState([]);
  const [defaultCountryCode, setDefaultCountryCode] = useState("");
  useEffect(() => {
    console.log("defaultCountryCode updated to: ", defaultCountryCode);
  }, [defaultCountryCode]);

  //Custom styles
  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent", // Dark background
      borderColor: "#4D4D4D70", // Dark border
      borderWidth: "1px",
      boxShadow: "none", // Remove shadow
      color: "#fff", // White text
      "&:hover": {
        borderColor: "#2e2d2d", // Lighter border on hover
        color: "#fff", // White text
      },
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "transparent", // Dark background for menu
      color: "#fff", // White text
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#2e2d2d"
        : state.isFocused
        ? "#2e2d2d"
        : "#2e2d2d", // Dark background for options
      color: "#fff", // White text

      "&:hover": {
        backgroundColor: "#2e2d2d", // Lighter background on hover
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#ffff", // White text for selected value
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgb(177, 177, 177)", // White text
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "transparent", // Dark indicator separator
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#fff", // White dropdown indicator
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff", // White text color in the search input
    }),
  };

  const {
    mutate: sendContactQuery,
    isLoading,
    error,
  } = useSendContactQuery(
    (data) => {
      toast.success(data.message);
      setIsSubmitting(false); // Reset loading state on success
    },
    (error) => {
      toast.error(error.message);
      setIsSubmitting(false); // Reset loading state on error
    }
  );

  const { isLoading: countryIsLoad, data: countryCodeList } =
    useGetCountryCodeList();

  useEffect(() => {
    if (countryCodeList) {
      const countryOptions = countryCodeList.data.map((val) => ({
        value: val.country_code_id,
        label: `${val.phone_code} (${val.name})`,
      }));

      setOptions(countryOptions);

      // Set the default country code (assuming US has the value '1')
      setDefaultCountryCode(
        countryOptions.find((opt) => opt.label.includes("United States"))
          ?.value || ""
      );
    }
  }, [countryCodeList]);

  return (
    <>
      <Container fluid className="content-area contact-container">
        <Row className="justify-content-center">
          <Col xs={12}>
            <div className="p-1 contact-title">
              <h2 className="section-title d-flex flex-column">
                {
                  getSettingData?.data.contact_setting_contact_title.split(
                    " "
                  )[0]
                }
                <span className="highlighted-text contact-highlight mt-2">
                  {" "}
                  {
                    getSettingData?.data.contact_setting_contact_title.split(
                      " "
                    )[1]
                  }
                </span>
              </h2>
            </div>
          </Col>

          <Col
            xs={12}
            md={3}
            className="text-center text-md-start enquiry_details mt-md-5"
          >
            <Row className="align-items-center contact-details-data ">
              <Col
                xs={12}
                md={2}
                className="d-flex justify-content-center justify-content-md-start custom-enquiry-icon"
              ></Col>
              <Col xs={12} md={9} className="enquiry-contacts mb-4 my-md-4">
                <Row>
                  <strong>
                    {" "}
                    <FontAwesomeIcon
                      icon={faPhone}
                      size="lg"
                      className="me-2"
                    />{" "}
                    {t("front_page.contacts_phone_label")}
                  </strong>
                </Row>
                <Row className="enquiry_phone ms-4">
                  {getSettingData?.data.contact_setting_enquiry_phone}
                </Row>
              </Col>
            </Row>
            <Row className="align-items-center contact-details-data">
              <Col
                xs={12}
                md={2}
                className="d-flex justify-content-center justify-content-md-start custom-enquiry-icon"
              ></Col>
              <Col xs={12} md={9} className="enquiry-contacts">
                <Row>
                  <strong>
                    {" "}
                    <FontAwesomeIcon
                      icon={faCommentDots}
                      size="lg"
                      className="me-2"
                    />{" "}
                    {t("front_page.contacts_email_label")}
                  </strong>
                </Row>
                <Row className="enquiry_mail ms-4">
                  {getSettingData?.data.contact_setting_enquiry_email}
                </Row>
              </Col>
            </Row>
          </Col>

          {/* Enquiry Form */}
          <Col xs={12} md={7} className="enquiry">
            <h3 className="text-uppercase text-center my-md-4">
              {t("front_page.contacts_send_enquiry_label")}
            </h3>
            <Formik
              enableReinitialize
              initialValues={{
                name: "",
                country_code_id: defaultCountryCode || "",
                mobile_number: "",
                email: "",
                message: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => {
                console.log("values: ", values);
                setIsSubmitting(true);
                sendContactQuery(values, {
                  onSuccess: () => {
                    resetForm();
                  },
                });
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
                setFieldValue,
              }) => (
                <Form
                  noValidate
                  onSubmit={handleSubmit}
                  className="p-4 contact-form"
                >
                  <Form.Group controlId="formName" className="mb-4 my-md-4">
                    <Form.Label className="my-md-3">
                      {t("front_page.contacts_name_label")} *
                    </Form.Label>
                    <Form.Control
                      className="bg-theme text-light contact-form-input"
                      type="text"
                      name="name"
                      placeholder={t("front_page.contacts_name_placeholder")}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.name && !!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    controlId="formMobileNumber"
                    className="mb-4 my-md-4"
                  >
                    <Form.Label className="my-md-3">
                      {t("front_page.contacts_mobile_label")} *
                    </Form.Label>
                    <Row className="d-flex justify-content-between">
                      <Col sm={4} className="contact-phone-code">
                        <Select
                          options={options}
                          styles={customStyles}
                          name="country_code_id"
                          value={options.find(
                            (opt) => opt.value === values.country_code_id
                          )}
                          onChange={(option) =>
                            setFieldValue("country_code_id", option.value)
                          }
                          onBlur={handleBlur}
                          className="me-2  mb-md-0"
                        />
                        {touched.country_code_id && errors.country_code_id && (
                          <div className="text-danger mt-1">
                            {errors.country_code_id}
                          </div>
                        )}
                      </Col>
                      <Col sm={8} className="contact-phone">
                        <Form.Control
                          type="text"
                          className="bg-theme text-light contact-form-input"
                          name="mobile_number"
                          placeholder={t(
                            "front_page.contacts_mobile_placeholder"
                          )}
                          value={values.mobile_number}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={
                            touched.mobile_number && !!errors.mobile_number
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.mobile_number}
                        </Form.Control.Feedback>
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group controlId="formEmail" className="mb-4 my-md-4">
                    <Form.Label className="my-md-3">
                      {t("front_page.contacts_email_input_label")} *
                    </Form.Label>
                    <Form.Control
                      className="bg-theme text-light contact-form-input"
                      type="email"
                      name="email"
                      placeholder={t("front_page.contacts_email_placeholder")}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.email && !!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formMessage" className="mb-4 my-md-4">
                    <Form.Label className="my-md-3">
                      {t("front_page.contacts_message_label")} *
                    </Form.Label>
                    <Form.Control
                      className="bg-theme text-light contact-form-input"
                      as="textarea"
                      rows={3}
                      name="message"
                      placeholder={t("front_page.contacts_message_placeholder")}
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.message && !!errors.message}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.message}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    type="submit"
                    className="w-100 my-2 enquiry-send-btn"
                    disabled={isSubmitting || isLoading} // Disable button during submission
                  >
                    {isSubmitting || isLoading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span className="ms-2">
                          {t("front_page.contacts_sending_spinner_label")}
                        </span>
                      </>
                    ) : (
                      `${t("front_page.contacts_submit_btn_label")}`
                    )}
                  </Button>

                  {error && (
                    <div className="mt-3 text-danger">{error.message}</div>
                  )}
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ContactUs;
