import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import header_logo from "assets/images/header_logo.png";
import { useOtpVerification, useResendOtp } from "hooks";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "store/features/authSlice";

const OTPVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpDisabled, setOtpDisabled] = useState(false);

  const handleChange = (element, index) => {
    let newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Auto focus to next input
    if (element.nextSibling && element.value) {
      element.nextSibling.focus();
    }
  };
  const forgot_token = useSelector((state) => state.user.forgot_token);
  const verifyToken = useSelector((state) => state.user.encoded_token);

  const handleVerify = () => {
    const values = {
      verification_otp: otp.join(""),
      encoded_token: forgot_token != "" ? forgot_token : verifyToken,
    };
    // Clear OTP inputs before sending the request
    setOtp(["", "", "", "", "", ""]);
    console.log("values: ", values);
    doVerifyOTP(values);
  };

  const { mutate: doVerifyOTP, isLoading: isVerifying } = useOtpVerification(
    (res) => {
      if (res.data) {
        if (forgot_token != "") {
          navigate("/reset-password");
        } else {
          dispatch(setToken({ encoded_token: "" }));
          navigate("/login");
          toast.success(res.message);
        }
      }
    }
  );

  const handleResendOtp = () => {
    setOtp(["", "", "", "", "", ""]); // Clear OTP inputs before sending the request
    doResendOTP({
      encoded_token: forgot_token != "" ? forgot_token : verifyToken,
    });
    setOtpDisabled(true);

    setTimeout(() => {
      setOtpDisabled(false);
    }, 60000);
  };

  const { mutate: doResendOTP, isLoading: isSending } = useResendOtp((res) => {
    if (res.data) {
      toast.success("OTP resent successfully. Please check your email.");
    }
  });
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      // Clear the current input and focus the previous one
      if (index >= 0) {
        let newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
        if (index > 0) {
          e.target.previousSibling.focus();
        }
      }
    }
  };
  return (
    <Container className="d-flex justify-content-center align-items-center mt-5 login-container">
      <div className="otp-content">
        <div className="text-center p-4 bg-theme text-white rounded otp-center-div">
          <div className="text-start">
            <Link to={"/"} className="pointer-event">
              <img src={header_logo} alt="Home logo" className="home-logo" />
            </Link>
            <h2 className="mt-4">OTP Verification</h2>
            <p>Please enter the OTP you received on your registered email</p>
          </div>
          <Form>
            <div className="text-start my-2">
              <h5>Enter OTP</h5>
            </div>
            <div className="otp-input">
              {otp.map((_, index) => (
                <Form.Control
                  key={index}
                  type="text"
                  maxLength="1"
                  className="mx-1 text-center"
                  value={otp[index]}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              ))}
            </div>
            <Button
              onClick={handleVerify}
              disabled={isVerifying}
              className="register-btn fw-bold"
            >
              {isVerifying ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  Verifying...
                </>
              ) : (
                "Verify"
              )}
            </Button>
          </Form>
          <p className="mt-3">
            Don't receive the OTP?{" "}
            <span
              className="text-decoration-underline hover-txt"
              style={{
                cursor: otpDisabled ? "not-allowed" : "pointer",
                color: otpDisabled ? "#aaa" : "#094936", // Change color when disabled
              }}
              onClick={otpDisabled ? null : handleResendOtp}
            >
              {otpDisabled ? "Sended" : "Resend OTP"}
            </span>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default OTPVerification;
