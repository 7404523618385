import React from "react";
import { Container, Form, Button, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import header_logo from "assets/images/header_logo.png";
import "assets/scss/page/_forgot_password.scss";
import { useForgotPassword } from "hooks"; // Assume this hook is created for handling forgot password requests
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import validationSchema from "./ForgotPasswordValidation";
import { useDispatch } from "react-redux";
import { setToken } from "store/features/authSlice";

const ForgotPasswordPage = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const { mutate: doForgotPassword } = useForgotPassword(
    (res) => {
      setIsSubmitting(false);
      if (res.data) {
        dispatch(setToken({ forgot_token: res.data.reset_token }));
        toast.success(res.message);
        navigate("/otp-verification");
      } else {
        toast.error(res.message || "Failed to send password reset email.");
      }
    },
    (err) => {
      setIsSubmitting(false);
      toast.error(t("front_page.forgot_password_error_message"));
    }
  );

  // Formik setup
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      setIsSubmitting(true);
      doForgotPassword({ email: values.email });
    },
  });

  return (
    <Container className="d-flex justify-content-center align-items-center mt-5">
      <div className="forgot-password-content">
        <div className="text-start p-4 bg-theme text-white rounded">
          <Link to={"/"} className="pointer-event">
            <img src={header_logo} alt="Home logo" className="home-logo" />
          </Link>
          <h2 className="mt-4">
            {t("front_page.forgot_password_forgot_pass_title")}
          </h2>
          <p>{t("front_page.forgot_password_otp_send_message")}</p>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>
                {t("front_page.forgot_password_email_label")}
              </Form.Label>
              <Form.Control
                type="email"
                placeholder={t("front_page.forgot_password_email_placeholder")}
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.email && !!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {t(formik.touched.email) && t(formik.errors.email)}
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              type="submit"
              disabled={isSubmitting}
              className="register-btn mt-3 fw-bold"
            >
              {isSubmitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  {t("front_page.forgot_password_sending_spinner")}
                </>
              ) : (
                `${t("front_page.forgot_password_send_btn_title")}`
              )}
            </Button>
          </Form>
        </div>
      </div>
    </Container>
  );
};

export default ForgotPasswordPage;
