import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { BusinessUserService } from "api";
const onDefaultError = (error) => {
  toast.error(error.message);
};

const useBecomeBusinessUser = (onSuccess, onError = onDefaultError) => {
  return useMutation(BusinessUserService.sendForm, {
    onSuccess,
    onError,
  });
};

const useListBusinessUsers = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["business_users_list"],
    () => {
      return BusinessUserService.listData();
    },
    {
      onSuccess,
      onError,
    }
  );
};

const useListBusinessType = (onSuccess, onError = onDefaultError) => {
  return useQuery(
    ["business_type_list"],
    () => {
      return BusinessUserService.listBusinessType();
    },
    {
      onSuccess,
      onError,
    }
  );
};

export { useBecomeBusinessUser, useListBusinessUsers, useListBusinessType };
