import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/page/_about.scss"; // Import custom CSS for specific styles
import { useGetAllTeamMemberData } from "hooks";

const About = ({ getSettingData, t }) => {
  const { data: getAllTeamMemberData } = useGetAllTeamMemberData(
    () => {},
    (error) => {
      console.log("In this api error occurs " + error.message);
    }
  );
  const teamMemberList = getAllTeamMemberData?.data?.team_member_list || [];

  return (
    <Container fluid className="about-us-container bg-theme text-white">
      {/* About Section */}
      <Row className="justify-content-start text-start mb-lg-4">
        <Col xs={12} sm={12}>
          <div className="about-container">
            <div>
              <h2 className="section-title d-flex flex-column">
                {getSettingData?.data.about_setting_who_we_are_title
                  .split(" ")[0]
                  .toUpperCase()}{" "}
                <span className="highlighted-text mt-2">
                  {getSettingData?.data.about_setting_who_we_are_title
                    .split(" ")[1]
                    ?.toUpperCase()}
                </span>
              </h2>
            </div>
            <p className="section-description mt-4">
              {getSettingData?.data.about_setting_who_we_are_description}
            </p>
          </div>
        </Col>
      </Row>

      {/* Mission Section */}
      <Row className="justify-content-start text-start mb-lg-4">
        <Col xs={12}>
          <div className="about-container">
            <h2 className="section-title section-title-diff">
              {getSettingData?.data.about_setting_our_mission_title}{" "}
              <span className="highlighted-text border-diff">
                {getSettingData?.data.about_setting_our_mission_subtitle}
              </span>
            </h2>
            <p className="section-description mt-4">
              {getSettingData?.data.about_setting_our_mission_description}
            </p>
          </div>
        </Col>
      </Row>

      {/* Vision Section */}
      <Row className="justify-content-start text-start mb-lg-4">
        <Col xs={12}>
          <div className="vision-section">
            <h2 className="section-title section-title-diff">
              {getSettingData?.data.about_setting_our_vision_title}{" "}
              <span className="highlighted-text border-diff">
                {getSettingData?.data.about_setting_our_vision_subtitle}
              </span>
            </h2>
            <p className="section-description mt-4">
              {getSettingData?.data.about_setting_our_vision_description}
            </p>
          </div>
        </Col>
      </Row>

      {/* Team Section */}
      <Row className="justify-content-center text-center  team-section">
        <Col xs={12} md={8}>
          <h2 className="section-title section-title-diff">
            {getSettingData?.data.about_setting_our_team_title.split(" ")[0]}{" "}
            <span className="highlighted-text border-diff">
              {getSettingData?.data.about_setting_our_team_title
                .split(" ")
                .slice(1)
                .join(" ")}
            </span>
          </h2>

          <p className="section-description team-description mt-4">
            {getSettingData?.data.about_setting_our_team_subtitle}
          </p>
        </Col>
        {teamMemberList.length !== 0 && (
          <Col xs={12}>
            <Row className="justify-content-center gap-2">
              {teamMemberList.map((teamMember, index) => (
                <Col
                  xs={10}
                  md={3}
                  className="mb-4 mx-auto mx-sm-0"
                  key={index}
                >
                  <Card className="overlay">
                    <Card.Header className="card-header">
                      <Card.Img
                        variant="top"
                        src={teamMember.image}
                        className="card-img"
                      />
                    </Card.Header>
                    <Card.Body className="card-body">
                      <Card.Title>{teamMember.full_name}</Card.Title>
                      <Card.Text>{teamMember.designation}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default About;
