import React from 'react'

function UserProfileLocationIcon() {
    return (
        <>
            <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.75 18.4163H15.5834V14.5205H18.4167V18.4163H21.25V11.6872L17 8.85384L12.75 11.6872V18.4163ZM17 27.4122C19.8806 24.7677 22.0174 22.3653 23.4104 20.2049C24.8035 18.0445 25.5 16.1261 25.5 14.4497C25.5 11.8761 24.6795 9.76877 23.0386 8.1278C21.3976 6.48683 19.3847 5.66634 17 5.66634C14.6153 5.66634 12.6025 6.48683 10.9615 8.1278C9.32051 9.76877 8.50002 11.8761 8.50002 14.4497C8.50002 16.1261 9.19655 18.0445 10.5896 20.2049C11.9827 22.3653 14.1195 24.7677 17 27.4122ZM17 31.1663C13.1986 27.9316 10.3594 24.9271 8.48231 22.1528C6.60523 19.3785 5.66669 16.8108 5.66669 14.4497C5.66669 10.908 6.80592 8.08648 9.0844 5.98509C11.3629 3.8837 14.0014 2.83301 17 2.83301C19.9986 2.83301 22.6372 3.8837 24.9156 5.98509C27.1941 8.08648 28.3334 10.908 28.3334 14.4497C28.3334 16.8108 27.3948 19.3785 25.5177 22.1528C23.6406 24.9271 20.8014 27.9316 17 31.1663Z" fill="white" />
            </svg>
        </>
    )
}

export default UserProfileLocationIcon