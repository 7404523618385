import { faArrowLeft, faFileLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import "assets/scss/page/_become_business_user.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Field, Formik } from "formik";
import Select from "react-select";
import axios from "axios";
import {
  useBecomeBusinessUser,
  useListBusinessType,
  useListBusinessUsers,
} from "hooks";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import validationSchema from "./BecomeBusinessUserValidation";
const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: "100%",
    height: "45px",
    // padding: '10px 18px',
    color: "white", // Replace with your $dark-purple value
    border: "2px solid #808080", // Replace with your $grey value
    borderRadius: "10px",
    fontSize: "16px",
    backgroundColor: "transparent",
    fontWeight: "300",
    boxShadow: "0px 0px 5px 0px rgb(0 0 0 / 25%)",
    "&:hover": {
      borderColor: "#4A4A4A", // Adjust color on hover if needed
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#0a5640" : "transparent",
    color: state.isSelected ? "black" : "black",
    // color: 'black',
    "&:hover": {
      backgroundColor: "#0a5640",
      color: "black",
    },
  }),
};
const EditBecomeUserPage = () => {
  const navigate = useNavigate();
  const [imagePreviews, setImagePreviews] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [businessTypeOptions, setBusinessTypeOptions] = useState([]);
  const [businessId, setBusinessId] = useState(null);
  const [selectedBusinessType, setSelectedBusinessType] = useState("");
  const [businessUserStatus, setBusinessUserStatus] = useState(0);

  const [initialValues, setInitialValues] = useState({
    businessName: "",
    businessType: "",
    location: "",
    webUrl: "",
  });
  const [existingImages, setExistingImages] = useState([]); // To store existing images from the API
  const fileInputRef = useRef(null);

  const { refetch: doGetList, isLoading: listingData } = useListBusinessUsers(
    (res) => {
      if (res.data) {
        const user = res.data;
        console.log("user: ", user);
        setBusinessUserStatus(user.status);
        setBusinessId(user.business_id != null ? user.business_id : null);
        setInitialValues({
          businessName: user.business_name || "",
          location: user.location || "",
          webUrl: user.web_url || "",
        });
        setSelectedBusinessType(user.business_type_id);
        // Assuming user.imageUrls is an array of URLs
        setExistingImages(user.imageUrls || []);
      }
    },
    (error) => {
      toast.error(error.message);
    }
  );
  const { refetch: doGetListBusinessType, isLoading: listingBusinessType } =
    useListBusinessType(
      (res) => {
        const opt = res.data.map((item) => {
          return { value: item.business_type_id, label: item.name };
        });
        setBusinessTypeOptions(opt);
      },
      (error) => {
        toast.error(error.message);
      }
    );

  const { mutate: sendForm, isLoading } = useBecomeBusinessUser(
    (data) => {
      toast.success(data.message);
      setImagePreviews([]);
      setSelectedFiles([]);
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Clear the file input
      }
      doGetList();
    },
    (error) => {
      toast.error(error.message);
    }
  );

  useEffect(() => {
    doGetList(); // Fetch the list of business users when the component mounts
  }, [doGetList]);

  const businessTypes = [
    { value: 1, label: "Retail" },
    { value: 2, label: "Service" },
    { value: 3, label: "Restaurant" },
    // Add more business types as needed
  ];

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newPreviews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews((prev) => [...prev, ...newPreviews]);
    setSelectedFiles((prev) => [...prev, ...files]);
  };

  const handleRemoveImage = (index) => {
    setImagePreviews((prev) => prev.filter((_, i) => i !== index));
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleRemoveExistingImage = (index) => {
    setExistingImages((prev) => prev.filter((_, i) => i !== index));
    // Optionally, you might want to handle the deletion in your backend if necessary
  };
  const handleTypeChange = (event) => {
    setSelectedBusinessType(event.target.value);
  };
  return (
    <>
      <div>
        <div className="d-flex business-heading">
          <div>
            <Button onClick={() => navigate("/dashboard")}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          </div>
          <div>
            <h3 className="ms-2">Become a Business User</h3>
          </div>
        </div>
        {businessUserStatus != undefined && businessUserStatus == 2 ? (
          <>
            <div className="d-flex justify-content-center mt-5">
              <div className="status-message">
                <div className="icon-div">
                  <FontAwesomeIcon className="text-dark" icon={faFileLines} />
                </div>
                <div className="msg-div">
                  <span className="text-dark me-4 msg-txt">
                    Details submitted to the Admin, once Admin will approve, you
                    can proceed further.
                  </span>
                </div>
                <div className="msg-status">Pending</div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="become-business-user-container">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              const formData = new FormData();
              formData.append("businessName", values.businessName);
              formData.append("businessType", selectedBusinessType);
              formData.append("location", values.location); // Use selected place
              formData.append("webUrl", values.webUrl);
              formData.append("businessId", businessId);

              selectedFiles.forEach((file) => {
                formData.append("business_images", file);
              });

              // You can also send existing images to keep them intact if needed
              existingImages.forEach((image) => {
                formData.append("existing_images", image); // Append the image URL directly
              });

              sendForm(formData);
              navigate("/dashboard");
              setSubmitting(false);
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              isSubmitting,
              errors,
              touched,
            }) => (
              <Form
                noValidate
                onSubmit={handleSubmit}
                className="business_user_form"
              >
                <Row className="my-3">
                  <Col md={6} className="mt-3">
                    <Form.Group controlId="businessName">
                      <input
                        type="hidden"
                        name="businessId"
                        value={businessId}
                      />
                      <Form.Label>Business Name</Form.Label>
                      <Field
                        type="text"
                        name="businessName"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.businessName && touched.businessName && (
                        <div className="invalid-feedback">
                          {errors.businessName}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mt-3">
                    <Form.Group controlId="businessType">
                      <Form.Label>Business Type</Form.Label>
                      <Form.Select
                        name="businessType"
                        style={customStyles}
                        className="form-control custom-select"
                        onChange={handleTypeChange}
                        value={selectedBusinessType}
                        onBlur={handleBlur}
                      >
                        <option value="">Select a business type</option>
                        {businessTypeOptions?.map((type) => (
                          <option key={type.value} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </Form.Select>
                      {errors.businessType && touched.businessType && (
                        <div className="invalid-feedback">
                          {errors.businessType}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col md={12}>
                    <Form.Group controlId="location">
                      <Form.Label>Location</Form.Label>
                      <Field
                        type="text"
                        name="location"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.location && touched.location && (
                        <div className="invalid-feedback">
                          {errors.location}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col md={12}>
                    <Form.Group controlId="formFileMultiple" className="mb-3">
                      <Form.Label>Upload Images</Form.Label>
                      <Form.Control
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        ref={fileInputRef}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {existingImages.length > 0 && (
                  <Row className="my-3">
                    <Col md={12}>
                      <div className="existing-image-previews">
                        {existingImages.map((image, index) => (
                          <div key={index} className="image-preview">
                            <img
                              src={image}
                              alt={`Existing Preview ${index + 1}`}
                            />
                            <Button
                              variant="link"
                              onClick={() => handleRemoveExistingImage(index)}
                              className="image-remove-cross"
                            >
                              ✖
                            </Button>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                )}

                {imagePreviews.length > 0 && (
                  <Row className="my-3">
                    <Col md={12}>
                      <div className="image-previews">
                        {imagePreviews.map((preview, index) => (
                          <div key={index} className="image-preview">
                            <img src={preview} alt={`Preview ${index + 1}`} />
                            <Button
                              variant="link"
                              onClick={() => handleRemoveImage(index)}
                              className="image-remove-cross"
                            >
                              ✖
                            </Button>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                )}
                <Row className="my-3">
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Website</Form.Label>
                      <Field
                        type="text"
                        name="webUrl"
                        className="form-control"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.webUrl && touched.webUrl && (
                        <div className="invalid-feedback">{errors.webUrl}</div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  type="submit"
                  variant="primary"
                  className="w-100 btn-submit-business-user"
                  disabled={isSubmitting || isLoading}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditBecomeUserPage;
